import React, { useCallback, useEffect, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "store/store";
import { PAGE_SIZES } from "constants/pagination";
import { ErrorAlert } from "components/common/old";
import { Wrapper } from "../../Approvements.styles";
import {
  ApprovementsTable,
  BasicTitle,
  TShortenedApprovement
} from "components/common/redesign";
import {
  offersForSellerGetActions,
  offersForSellerGetSelectors
} from "store/offersForSeller/get";
import {
  offerForSellerUpdateStatusActions,
  offerForSellerUpdateStatusSelectors
} from "store/offersForSeller/update_status";
import { getOffersForSeller } from "store/offersForSeller/get/thunk";
import { updateOfferForSellerStatus } from "store/offersForSeller/update_status/thunk";
import { userSelectors } from "store/user";

export const OfferApprovementsForSeller = () => {
  const dispatch = useAppDispatch();

  const { user, isAdmin } = useAppSelector(userSelectors.getState);

  const { offersForSeller, totalElements, page, size } = useAppSelector(
    offersForSellerGetSelectors.getState
  );
  const { isLoading: statusIsLoading, error: statusError } = useAppSelector(
    offerForSellerUpdateStatusSelectors.getState
  );

  const getOffersArr = useCallback(() => {
    dispatch(getOffersForSeller());
  }, [dispatch]);

  const onPaginationChange = useCallback(
    (page: number, pageSize: number) => {
      if (pageSize === size) {
        dispatch(offersForSellerGetActions.setPage(page));
      } else {
        dispatch(offersForSellerGetActions.setPage(1));
        dispatch(offersForSellerGetActions.setSize(pageSize));
      }
      getOffersArr();
    },
    [dispatch, getOffersArr, size]
  );

  const onUpdateStatus = useCallback(
    (offer_id: number, new_status_id: number) => {
      dispatch(
        updateOfferForSellerStatus({
          offer_id,
          new_status_id
        })
      )
        .unwrap()
        .then(() => {
          getOffersArr();
        });
    },
    [dispatch, getOffersArr]
  );
  const shortenedApprovements: TShortenedApprovement[] = useMemo(
    () =>
      (offersForSeller || [])
        .filter((offer) => {
          const sellerEntity = user?.entities?.find(
            (entity) =>
              offer?.seller_entity?.entity_id === entity?.entity?.entity_id
          );

          return (
            (sellerEntity?.role_id &&
              sellerEntity?.role_id >= 2 &&
              offer?.creator?.user_id === user?.user_id) ||
            (sellerEntity?.role_id && sellerEntity?.role_id >= 3) ||
            (sellerEntity?.entity?.cabinet_id === user?.cabinet?.cabinet_id &&
              user?.cabinet_role_id === 2) ||
            isAdmin
          );
        })
        .map((offer) => ({
          name: `Предложение № ${offer?.offer_id} к заявке № ${offer?.application_id}`,
          approvement_id: offer?.offer_id,
          type: "offers",
          parent_id: offer?.offer_id?.toString()
        })),
    [
      isAdmin,
      offersForSeller,
      user?.cabinet?.cabinet_id,
      user?.cabinet_role_id,
      user?.entities,
      user?.user_id
    ]
  );

  useEffect(() => {
    dispatch(offersForSellerGetActions.setSize(200));
    dispatch(offersForSellerGetActions.setSearch("status.id:123"));
    getOffersArr();
  }, [dispatch, getOffersArr]);

  useEffect(() => {
    return () => {
      dispatch(offersForSellerGetActions.clearState());
      dispatch(offerForSellerUpdateStatusActions.clearState());
    };
  }, [dispatch]);

  return (
    <Wrapper>
      <BasicTitle>Согласования</BasicTitle>

      <ErrorAlert error={statusError} />

      <ApprovementsTable
        approvements={shortenedApprovements}
        onUpdate={onUpdateStatus}
        isLoading={statusIsLoading}
        okCode={101}
        cancelCode={107}
        pagination={{
          current: page,
          onChange: onPaginationChange,
          pageSize: size,
          total: totalElements,
          showSizeChanger: true,
          pageSizeOptions: PAGE_SIZES
        }}
      />
    </Wrapper>
  );
};
