import React, { FC, useMemo } from "react";
import {
  TApplication,
  TApplicationForSeller,
  TOfferForBuyer,
  TOfferForSeller
} from "types";
import {
  ColumnsTitle,
  PricesBlock,
  TableScrollWrapper,
  TableWrapper
} from "./OffersComparisonTable.styles";
import { Table, TableColumnsType, Tooltip, Typography } from "antd";
import { Link } from "react-router-dom";
import { toDisplayDate } from "utils/dates";
import { padFractionalZeros, splitThousands } from "utils/numbers";
import { WarningOutlined } from "@ant-design/icons";
import { Empty } from "../Empty/Empty";
type TPrice = {
  price?: number;
  isMin?: boolean;
};

type TPositionForTable = {
  position_name: string;
  position_id: number;
  prices_per_offer: Record<number, TPrice[]>;
};

type TOffersComparisonTableProps = {
  application: TApplication | TApplicationForSeller;
  offers?: (TOfferForBuyer | TOfferForSeller)[];
};

export const OffersComparisonTable: FC<TOffersComparisonTableProps> = ({
  application,
  offers
}) => {
  const columns: TableColumnsType<TPositionForTable> = useMemo(
    () => [
      {
        title: "Наименование",
        dataIndex: "position_name",
        minWidth: 250,
        render: (value: string, _: any, index: number) => ({
          children: value,
          props: {
            rowSpan: index % 2 === 0 ? 2 : 0
          }
        })
      },
      ...(offers || [])?.map((offer) => ({
        title: (
          <ColumnsTitle>
            <Link to={`${"../../offers"}/${offer?.offer_id}`}>
              {offer?.offer_id}{" "}
              {offer?.create_ts && `от ${toDisplayDate(offer?.create_ts)}`}
            </Link>
            <Typography.Text>
              {offer?.seller_entity?.short_name}
            </Typography.Text>
          </ColumnsTitle>
        ),
        key: offer?.offer_id,
        minWidth: 175,
        render: (
          value: TPositionForTable,
          _: TPositionForTable,
          index: number
        ) => {
          const prices = value?.prices_per_offer?.[offer?.offer_id];
          return (
            <PricesBlock>
              {prices?.map((price) => (
                <Typography.Text
                  type={
                    price?.isMin
                      ? index % 2 === 0
                        ? "success"
                        : "warning"
                      : undefined
                  }
                  strong={price?.isMin}
                >
                  {splitThousands(padFractionalZeros(price?.price))} руб.
                  {index % 2 !== 0 && (
                    <>
                      {" "}
                      <Typography.Text>
                        <Tooltip title="Аналог">
                          <WarningOutlined />
                        </Tooltip>
                      </Typography.Text>
                    </>
                  )}
                </Typography.Text>
              ))}
            </PricesBlock>
          );
        }
      }))
    ],
    [offers]
  );

  const data: TPositionForTable[] = useMemo(() => {
    const originalPositionsMap: Record<number, Record<number, TPrice[]>> = {};
    const analoguePositionsMap: Record<number, Record<number, TPrice[]>> = {};

    const minOriginalPricesMap: Record<number, number> = {};
    const minAnaloguePricesMap: Record<number, number> = {};

    offers?.forEach((offer) => {
      const offer_id = offer?.offer_id;
      offer?.positions?.forEach((position) => {
        const position_id = position?.application_position_id;
        const position_price = position?.unit_price;
        if (!position_id) {
          return;
        }
        const mapper = position?.analogue_flag
          ? analoguePositionsMap
          : originalPositionsMap;
        const pricesMapper = position?.analogue_flag
          ? minAnaloguePricesMap
          : minOriginalPricesMap;

        if (!mapper[position_id]) {
          mapper[position_id] = {};
        }
        if (!mapper[position_id][offer_id]) {
          mapper[position_id][offer_id] = [];
        }
        mapper[position_id][offer_id]?.push({
          price: position_price
        });

        if (
          pricesMapper[position_id] === undefined ||
          (pricesMapper[position_id] !== undefined &&
            position_price < pricesMapper[position_id])
        ) {
          pricesMapper[position_id] = position_price;
        }
      });
    });

    Object?.entries(originalPositionsMap)?.forEach(
      ([position_id, pricesByOffer]) => {
        Object.values(pricesByOffer)?.forEach((prices) => {
          prices?.forEach((price) => {
            if (price?.price === minOriginalPricesMap[Number(position_id)]) {
              price.isMin = true;
            }
          });
        });
      }
    );
    Object?.entries(analoguePositionsMap)?.forEach(
      ([position_id, pricesByOffer]) => {
        Object.values(pricesByOffer)?.forEach((prices) => {
          prices?.forEach((price) => {
            if (price?.price === minAnaloguePricesMap[Number(position_id)]) {
              price.isMin = true;
            }
          });
        });
      }
    );

    return application?.positions?.reduce((acc: TPositionForTable[], pos) => {
      return [
        ...acc,
        {
          position_id: pos?.position_id,
          position_name: pos?.position_name,
          prices_per_offer: originalPositionsMap[pos?.position_id]
        },
        {
          position_id: pos?.position_id,
          position_name: pos?.position_name,
          prices_per_offer: analoguePositionsMap[pos?.position_id]
        }
      ];
    }, []);
  }, [application?.positions, offers]);

  return (
    <TableWrapper>
      {offers?.length ? (
        <>
          <TableScrollWrapper>
            <Table dataSource={data} columns={columns} pagination={false} />
          </TableScrollWrapper>
        </>
      ) : (
        <Empty
          description="Пока нет ни одного предложения"
          style={{ flex: 1 }}
        />
      )}
    </TableWrapper>
  );
};
