import React, { FC, useCallback, useMemo } from "react";
import {
  TApplicationEditFormTabProps,
  TApplicationFormValues
} from "../../ApplicationEditForm";
import {
  DatePicker,
  Form,
  Input,
  InputNumber,
  Radio,
  Select,
  Typography
} from "antd";
import dayjs from "dayjs";
import { DATE_FORMAT } from "constants/dates";
import { Divider, TypographyTitleNoMargin } from "components/common/redesign";
import { CTABlock, NavButton } from "../../ApplicationEditForm.styles";
import { projectRegionIdMapper } from "utils/mappers";
import { getMidDay } from "utils/dates/getMidDay";

export const GeneralTab: FC<TApplicationEditFormTabProps> = ({
  formData,
  entities,
  projects,
  locations,
  accounts,
  onSubmitStep
}) => {
  const [form] = Form.useForm<TApplicationFormValues>();

  const deliveryNeeded = Form.useWatch("is_need_delivery", form);
  const postpaymentNeeded = Form.useWatch("is_postpayment", form);
  const buyerEntity = Form.useWatch("buyer_entity", form);
  const projectId = Form.useWatch("project_id", form);

  const entitiesOptions = useMemo(
    () =>
      entities?.map((entity) => ({
        label: entity?.short_name,
        value: entity?.entity_id
      })),
    [entities]
  );

  const projectsOptions = useMemo(
    () =>
      (projects || [])
        .filter((project) => project?.entity_id === buyerEntity)
        .map((project) => ({
          label: project?.project_name,
          value: project?.project_id
        })),
    [buyerEntity, projects]
  );
  const accountsOptions = useMemo(
    () =>
      (accounts || [])
        .filter((account) => account?.entity_id === buyerEntity)
        .map((account) => ({
          label: account?.name,
          value: account?.account_id
        })),
    [accounts, buyerEntity]
  );
  const locationsOptions = useMemo(
    () =>
      (locations || [])
        .filter((location) => location?.entity_id === buyerEntity)
        .map((location) => ({
          label: location?.address,
          value: location?.location_id
        })),
    [locations, buyerEntity]
  );

  const selectedProject = useMemo(
    () => projects?.find((project) => project?.project_id === projectId),
    [projectId, projects]
  );

  const initialValues: TApplicationFormValues = useMemo(() => {
    const {
      application_name,
      application_delivery_due_timestamp,
      project_id,
      is_need_delivery,
      location,
      application_comment,
      buyer_account,
      is_postpayment,
      postpayment_days
    } = formData;

    return {
      application_name,
      buyer_entity: projects?.find(
        (project) => project?.project_id === project_id
      )?.entity_id,
      application_delivery_due_timestamp: application_delivery_due_timestamp
        ? dayjs(application_delivery_due_timestamp)
        : undefined,
      project_id,
      is_need_delivery,
      location: location?.location_id,
      application_comment,
      buyer_account: buyer_account?.account_id,
      is_postpayment,
      postpayment_days
    };
  }, [formData, projects]);

  const onSubmitHandler = useCallback(
    (application: TApplicationFormValues) => {
      const {
        application_name,
        application_delivery_due_timestamp,
        project_id,
        is_need_delivery,
        location,
        application_comment,
        buyer_account,
        is_postpayment,
        postpayment_days
      } = application;
      onSubmitStep({
        application_name,
        application_delivery_due_timestamp: getMidDay(
          application_delivery_due_timestamp
        ),
        project_id,
        is_need_delivery,
        location: is_need_delivery
          ? location
            ? locations?.find((item) => item?.location_id === location)
            : undefined
          : undefined,
        application_comment,
        buyer_account: accounts?.find(
          (account) => account?.account_id === buyer_account
        ),
        is_postpayment,
        postpayment_days: is_postpayment ? postpayment_days : undefined
      });
    },
    [accounts, locations, onSubmitStep]
  );

  const onEntityChange = useCallback(() => {
    form.setFieldValue("project_id", undefined);
    form.setFieldValue("buyer_account", undefined);

    form.setFieldValue("location", undefined);
  }, [form]);
  const onProjectChange = useCallback(
    (project_id: number) => {
      const project = projects?.find(
        (project) => project?.project_id === project_id
      );

      form.setFieldValue("location", project?.location_id);
    },
    [form, projects]
  );

  return (
    <>
      <Form
        name="application_edit_general"
        form={form}
        layout="vertical"
        requiredMark={false}
        style={{ maxWidth: "400px" }}
        onFinish={onSubmitHandler}
        autoComplete="off"
        initialValues={initialValues}
      >
        <Form.Item<TApplicationFormValues>
          label="Наименование заявки"
          name="application_name"
          rules={[
            {
              required: true,
              whitespace: true,
              message: "Введите наименование заявки"
            }
          ]}
        >
          <Input />
        </Form.Item>

        <Divider />

        <Form.Item>
          <TypographyTitleNoMargin level={5}>Поставка</TypographyTitleNoMargin>
        </Form.Item>

        <Form.Item<TApplicationFormValues>
          label="Желаемая дата поставки"
          name="application_delivery_due_timestamp"
          rules={[
            {
              required: true,
              message: "Выберите желаемую дату поставки"
            }
          ]}
        >
          <DatePicker format={DATE_FORMAT} minDate={dayjs()} />
        </Form.Item>

        <Form.Item<TApplicationFormValues>
          label="Юр. лицо"
          name="buyer_entity"
          rules={[
            {
              required: true,
              message: "Выберите юр. лицо"
            }
          ]}
        >
          <Select
            options={entitiesOptions}
            onChange={onEntityChange}
            value={buyerEntity}
          />
        </Form.Item>

        <Form.Item<TApplicationFormValues>
          label="Проект покупателя"
          name="project_id"
          rules={[
            {
              required: true,
              message: "Выберите проект покупателя"
            }
          ]}
        >
          <Select
            options={projectsOptions}
            disabled={!projectsOptions?.length}
            onChange={onProjectChange}
          />
        </Form.Item>

        <Form.Item<TApplicationFormValues> label="Регион">
          <Typography.Text>
            {selectedProject?.region_id &&
              projectRegionIdMapper[selectedProject?.region_id]?.fullName}
          </Typography.Text>
        </Form.Item>

        <Form.Item<TApplicationFormValues>
          label="Необходима доставка"
          name="is_need_delivery"
          rules={[
            {
              required: true,
              message: "Выберите одну из опций"
            }
          ]}
        >
          <Radio.Group>
            <Radio value={true}>Да</Radio>
            <Radio value={false}>Нет</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item<TApplicationFormValues>
          label="Адрес доставки"
          name="location"
        >
          <Select
            options={locationsOptions}
            disabled={!deliveryNeeded || !locationsOptions?.length}
          />
        </Form.Item>

        <Form.Item<TApplicationFormValues>
          label="Комментарий для поставщика"
          name="application_comment"
        >
          <Input />
        </Form.Item>

        <Divider />

        <Form.Item>
          <TypographyTitleNoMargin level={5}>Оплата</TypographyTitleNoMargin>
        </Form.Item>

        <Form.Item<TApplicationFormValues>
          label="Счёт покупателя"
          name="buyer_account"
          rules={[
            {
              required: true,
              message: "Выберите счёт покупателя"
            }
          ]}
        >
          <Select
            options={accountsOptions}
            disabled={!accountsOptions?.length}
          />
        </Form.Item>
        <Form.Item<TApplicationFormValues>
          label="Необходим отложенный платеж"
          name="is_postpayment"
          rules={[
            {
              required: true,
              message: "Выберите одну из опций"
            }
          ]}
        >
          <Radio.Group>
            <Radio value={true}>Да</Radio>
            <Radio value={false}>Нет</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item<TApplicationFormValues>
          label="Кол-во дней отложенного платежа"
          name="postpayment_days"
        >
          <InputNumber disabled={!postpaymentNeeded} precision={0} />
        </Form.Item>
      </Form>

      <CTABlock>
        <NavButton
          type="primary"
          form="application_edit_general"
          htmlType="submit"
        >
          Далее
        </NavButton>
      </CTABlock>
    </>
  );
};
