import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { THistoryStatusesByTypeAndEntityIdState } from "./types";

import { RequestError, THistoryItem } from "types";

const initialState: THistoryStatusesByTypeAndEntityIdState = {
  isLoading: false,
  error: null,
  history: undefined
};

export const HistoryStatusesByTypeAndEntityIdSlice = createSlice({
  name: "history/statusesByTypeAndEntityId",
  initialState,
  reducers: {
    clearState: () => initialState,
    setHistory: (state, { payload }: PayloadAction<THistoryItem[]>) => {
      state.history = payload;
    },
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
    setError: (state, { payload }: PayloadAction<RequestError | null>) => {
      state.error = payload;
    }
  }
});

export const historyStatusesByTypeAndEntityIdActions =
  HistoryStatusesByTypeAndEntityIdSlice.actions;
export const historyStatusesByTypeAndEntityIdReducer =
  HistoryStatusesByTypeAndEntityIdSlice.reducer;

export * as historyStatusesByTypeAndEntityIdSelectors from "./selectors";
