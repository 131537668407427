import React, { useMemo } from "react";
import {
  CalendarWrapper,
  // ContentCard,
  ContentColumn,
  HeaderCard,
  Title,
  Wrapper
} from "./HomePage.styles";
import { DEVICE } from "constants/breakpoints";
import { useMediaQuery } from "react-responsive";
import { Calendar, Tabs } from "antd";
import { useAppSelector } from "store/store";
import { userSelectors } from "store/user";
import { ColleaguesActivity, PersonalActivity } from "./components";

export const HomePage = () => {
  // const dispatch = useAppDispatch();

  // const { history } = useAppSelector(historyByCabinetIdSelectors.getState);
  const { user } = useAppSelector(userSelectors.getState);
  const isXXL = useMediaQuery({ query: DEVICE.XXL });
  const isSM = useMediaQuery({ query: DEVICE.SM });

  // const getHistory = useCallback(() => {
  //   dispatch(getHistoryByCabinetId());
  // }, [dispatch]);

  // useEffect(() => {
  //   if (user) {
  //     getHistory();
  //   }
  // }, [getHistory, user]);

  const colleaguesActivityAvailable = useMemo(
    () => user?.cabinet_role_id === 2,
    [user?.cabinet_role_id]
  );

  return (
    <>
      <Wrapper>
        <ContentColumn>
          <HeaderCard
            title={
              <Title level={!isXXL ? 3 : 4}>
                Здравствуйте, {user?.last_name} {user?.first_name}!
              </Title>
            }
            type="inner"
          >
            <Tabs
              size={isSM ? "small" : undefined}
              destroyInactiveTabPane
              items={[
                {
                  key: "personalActivity",
                  label: "Моя история",
                  children: <PersonalActivity />
                },
                ...(colleaguesActivityAvailable
                  ? [
                      {
                        key: "colleaguesActivity",
                        label: "История коллег",
                        children: <ColleaguesActivity />
                      }
                    ]
                  : [])
              ]}
            />
          </HeaderCard>
          {/* <ContentCard
            title={
              <>
                <Typography.Text>
                  Мы рады видеть Вас на платформе Contractor!
                </Typography.Text>
                <br />
                <Typography.Text style={{ fontWeight: 400 }}>
                  На данный момент мы активно работаем над расширением
                  функционала, чтобы предоставить вам более полную и полезную
                  информацию о ваших{" "}
                  {currentRole === ECabinetRole.SELLER
                    ? "предложениях"
                    : "заявках"}{" "}
                  и активности команды.
                </Typography.Text>
              </>
            }
            type="inner"
          >
            <List
              header="Совсем скоро здесь появятся:"
              footer={
                <>
                  <Typography.Text>
                    Мы стремимся создать для вас интуитивно понятный и удобный
                    инструмент для работы. Благодарим вас за понимание и
                    терпение, пока мы готовим эти улучшения. Уже скоро платформа
                    будет предоставлять всю необходимую информацию
                    для эффективной работы!
                    <br />
                    <br />
                    Следите за обновлениями, и будьте готовы к новому уровню
                    удобства и контроля!
                  </Typography.Text>
                </>
              }
              dataSource={[
                {
                  label: "Новые сообщения от ваших партнеров",
                  text: "мгновенно узнавайте о любых важных обновлениях и запросах."
                },
                {
                  label: `Статистика по ${currentRole === ECabinetRole.SELLER ? "предложениям" : "заявкам"}`,
                  text: "отслеживайте прогресс ваших задач и общую динамику команды."
                },
                {
                  label: "История изменений",
                  text: `получайте уведомления об обновлениях по ${currentRole === ECabinetRole.SELLER ? "предложениям" : "заявкам"}, чтобы оставаться в курсе.`
                }
              ]}
              renderItem={(item) => (
                <List.Item>
                  <Typography.Text>
                    <Typography.Text strong>{item.label}</Typography.Text> —{" "}
                    {item.text}
                  </Typography.Text>
                </List.Item>
              )}
            />
          </ContentCard> */}
        </ContentColumn>
        <CalendarWrapper>
          <Calendar fullscreen={false} />
        </CalendarWrapper>
      </Wrapper>
    </>
  );
};
