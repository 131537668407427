import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import {
  Content,
  ContentColumn,
  CredText,
  CTABlock,
  Darkener,
  Footer,
  Header,
  LayoutStyled,
  Logo,
  LogoLink,
  MainWrapper,
  MenuColumn,
  MenuStyled,
  ProfileBlock,
  ProfilePositionText,
  ProfileTexts,
  StyledButton
} from "./AuthorizedLayout.styles";
import logoNamed from "images/logoNamed.png";
import { Button, Dropdown, MenuProps, Modal } from "antd";
import {
  CheckSquareFilled,
  CrownFilled,
  HomeFilled,
  MailFilled,
  MenuOutlined,
  QuestionCircleOutlined,
  ShopFilled,
  SmileFilled,
  SwapOutlined,
  TagsFilled,
  UserOutlined
} from "@ant-design/icons";
import { useMediaQuery } from "react-responsive";
import { DEVICE } from "constants/breakpoints";
import { useAppDispatch, useAppSelector } from "store/store";
import { userActions, userSelectors } from "store/user";
import { BasicTitle, ProfileAvatar } from "components/common/redesign";
import { getApplicationsTableSettings } from "store/settings/applicationsTable/thunk";
import { getOffersTableSettings } from "store/settings/offersTable/thunk";
import { ECabinetRole } from "types";

export const AuthorizedLayout = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();

  const isXL = useMediaQuery({ query: DEVICE.XL });
  const isLG = useMediaQuery({ query: DEVICE.LG });
  const isMD = useMediaQuery({ query: DEVICE.MD });
  const isSM = useMediaQuery({ query: DEVICE.SM });

  const { user, currentRole, isAdmin } = useAppSelector(userSelectors.getState);

  const [menuOpened, setMenuOpened] = useState(true);
  const [mobileMenuOpened, setMobileMenuOpened] = useState(false);
  const [openSwitchRoleModal, setOpenSwitchRoleModal] = useState(false);

  const contentNotScrollable = useMemo(
    () => location.pathname.includes("messages"),
    [location.pathname]
  );

  const toggleMenu = useCallback(() => {
    setMenuOpened((prev) => !prev);
  }, []);
  const toggleMobileMenu = useCallback((e: React.MouseEvent) => {
    e.stopPropagation();
    setMobileMenuOpened((prev) => !prev);
  }, []);
  const closeMobileMenu = useCallback(() => {
    setMobileMenuOpened(false);
  }, []);

  const showSwitchRoleModal = useCallback(() => {
    setOpenSwitchRoleModal(true);
  }, []);

  const closeSwitchRoleModal = useCallback(() => {
    setOpenSwitchRoleModal(false);
  }, []);

  const onSwitchCabinerRole = useCallback(() => {
    if (currentRole === ECabinetRole.SELLER) {
      dispatch(userActions.setCurrentRole(ECabinetRole.BUYER));
    } else if (currentRole === ECabinetRole.BUYER) {
      dispatch(userActions.setCurrentRole(ECabinetRole.SELLER));
    }
    closeSwitchRoleModal();
  }, [closeSwitchRoleModal, currentRole, dispatch]);

  const menuItems: MenuProps["items"] = useMemo(
    () => [
      /* TODO другие иконки */
      ...(isSM
        ? [
            {
              key: "messages",
              icon: <MailFilled />,
              label: "Сообщения"
              // extra: <Badge count={0} showZero />
            },
            // {
            //   key: "notifications",
            //   icon: <BellFilled />,
            //   label: "Уведомления",
            //   extra: <Badge count={0} showZero />
            // },
            { key: "profile", icon: <SmileFilled />, label: "Профиль" },
            {
              type: "divider"
            } as Required<MenuProps>["items"][number]
          ]
        : []),
      { key: "", icon: <HomeFilled />, label: "Домашняя страница" },
      { key: "applications", icon: <ShopFilled />, label: "Заявки" },
      { key: "offers", icon: <TagsFilled />, label: "Предложения" },
      {
        key: "approvements",
        icon: <CheckSquareFilled />,
        label: "Согласования"
      },
      {
        type: "divider"
      },
      ...(user?.cabinet?.cabinetRole === ECabinetRole.MULTIPLE
        ? [
            {
              key: "currentRole",
              icon: <UserOutlined />,
              label:
                currentRole === ECabinetRole.SELLER ? "Поставщик" : "Покупатель"
            },
            {
              key: "switch",
              icon: <SwapOutlined />,
              label: `Сменить роль`,
              onClick: showSwitchRoleModal,
              danger: true
            },
            {
              type: "divider"
            } as Required<MenuProps>["items"][number]
          ]
        : []),
      {
        key: "help",
        icon: <QuestionCircleOutlined />,
        label: "Поддержка"
      },
      ...(isAdmin
        ? [
            {
              key: "admin",
              icon: <CrownFilled />,
              label: "Администратор"
            }
          ]
        : [])
    ],
    [
      currentRole,
      isAdmin,
      isSM,
      showSwitchRoleModal,
      user?.cabinet?.cabinetRole
    ]
  );

  const curMenuItems = useMemo(
    () => ["currentRole", location.pathname.split("/")[1]].filter((i) => !!i),
    [location]
  );

  useEffect(() => {
    dispatch(getApplicationsTableSettings());
    dispatch(getOffersTableSettings());
  }, [dispatch]);

  return (
    <>
      <LayoutStyled>
        <Header onClick={isLG ? closeMobileMenu : undefined}>
          <LogoLink to="/">
            <Logo src={logoNamed} />
          </LogoLink>
          {!isSM ? (
            <CTABlock>
              {/* TODO другие иконки */}
              <Link to="/messages">
                {/* <Badge count={0} showZero offset={[0, 10]}> */}
                <StyledButton
                  icon={<MailFilled />}
                  type="text"
                  size={isXL ? "middle" : "large"}
                />
                {/* </Badge> */}
              </Link>
              {/* <Link to="/notifications">
              <Badge count={0} showZero offset={[0, 10]}>
                <StyledButton
                  icon={<BellFilled />}
                  type="text"
                  size={isXL ? "middle" : "large"}
                />
              </Badge>
            </Link> */}
              <Link to="/profile">
                <ProfileBlock>
                  <ProfileAvatar
                    size={40}
                    shape="square"
                    isSeller={currentRole === ECabinetRole.SELLER}
                  >
                    {user?.last_name?.[0]}
                    {user?.first_name?.[0]}
                  </ProfileAvatar>
                  {!isMD && (
                    <ProfileTexts>
                      <BasicTitle level={5}>
                        {user?.last_name} {user?.first_name}
                      </BasicTitle>
                      <ProfilePositionText>
                        {user?.position}
                      </ProfilePositionText>
                    </ProfileTexts>
                  )}
                </ProfileBlock>
              </Link>
            </CTABlock>
          ) : (
            <Dropdown
              menu={{
                items: menuItems,
                selectedKeys: curMenuItems,
                onClick: ({ key }) => {
                  closeMobileMenu();
                  if (key !== "switch" && key !== "currentRole") {
                    navigate(`../${key}`);
                  }
                }
              }}
              open={mobileMenuOpened}
              placement="bottomRight"
              trigger={["click"]}
              overlayClassName={"ant-dd-menu-custom"}
            >
              <Button
                icon={<MenuOutlined />}
                onClick={toggleMobileMenu}
                type="primary"
                size="large"
              />
            </Dropdown>
          )}
        </Header>
        <MainWrapper>
          {!isSM && (
            <>
              <MenuColumn $opened={!isLG && menuOpened}>
                {!isLG ? (
                  <Button
                    icon={<MenuOutlined />}
                    onClick={toggleMenu}
                    type="primary"
                    size="large"
                  />
                ) : (
                  <Dropdown
                    menu={{
                      items: menuItems,
                      selectedKeys: curMenuItems,
                      onClick: ({ key }) => {
                        closeMobileMenu();
                        if (key !== "switch" && key !== "currentRole") {
                          navigate(`../${key}`);
                        }
                      }
                    }}
                    open={mobileMenuOpened}
                    placement="bottomLeft"
                    trigger={["click"]}
                    overlayClassName={"ant-dd-menu-custom"}
                  >
                    <Button
                      icon={<MenuOutlined />}
                      onClick={toggleMobileMenu}
                      type="primary"
                      size="large"
                    />
                  </Dropdown>
                )}
                <MenuStyled
                  selectedKeys={curMenuItems}
                  mode="inline"
                  inlineCollapsed={!isLG ? !menuOpened : true}
                  items={menuItems}
                  onClick={({ key }) => {
                    if (key !== "switch" && key !== "currentRole") {
                      navigate(`../${key}`);
                    }
                  }}
                />
              </MenuColumn>
            </>
          )}
          {isLG && mobileMenuOpened && <Darkener onClick={closeMobileMenu} />}

          <ContentColumn>
            <Content $notScrollable={contentNotScrollable}>
              <Outlet />
            </Content>
            <Footer $opened={menuOpened}>
              <CredText>Contractor DEV @{new Date().getFullYear()}</CredText>
            </Footer>
          </ContentColumn>
        </MainWrapper>

        {/* <ImageWrapper>
        <Image src={unauthorizedBg} />
        <TextBlock>
          <Title level={2}>Авторизованный лейаут</Title>
          <Subtitle level={4}>
            Продавцы и закупщики на одной волне для взаимного роста.
          </Subtitle>
        </TextBlock>
      </ImageWrapper>
      <MainWrapper>
        <Header>
          <LogoLink to="/">
            <Logo src={logoNamed} />
          </LogoLink>
        </Header>
        <Main>
          <MainContent>
          </MainContent>
          <Footer>
            <CredText>Contractor DEV @{new Date().getFullYear()}</CredText>
          </Footer>
        </Main>
      </MainWrapper> */}
      </LayoutStyled>

      <Modal
        open={openSwitchRoleModal}
        title={`Перейти к роли "${currentRole === ECabinetRole.SELLER ? "Покупатель" : "Поставщик"}"`}
        onCancel={closeSwitchRoleModal}
        destroyOnClose
        footer={[
          <Button key="back" onClick={closeSwitchRoleModal}>
            Отмена
          </Button>,
          <Button
            // icon={<DeleteFilled />}
            danger
            type="primary"
            onClick={onSwitchCabinerRole}
          >
            Перейти
          </Button>
        ]}
      >
        Вы уверены что хотите перейти к роли "
        {currentRole === ECabinetRole.SELLER ? "Покупатель" : "Поставщик"}"?
        <br />
        <br />
        {currentRole === ECabinetRole.SELLER
          ? "В новой роли вы сможете создавать заявки и получать предложения к ним."
          : "В новой роли вы сможете просматривать открытые заявки и составлять предложения к ним."}
        <br />
        <br />
        {currentRole === ECabinetRole.SELLER
          ? "Но вы не сможете создавать предложения к другим заявкам."
          : "Но вы не сможете создавать заявки и получать предложения."}
      </Modal>
    </>
  );
};
