import React, { useCallback, useEffect } from "react";
import { HistoryFooter } from "components/common/redesign";
import { PAGE_SIZES } from "constants/pagination";
import {
  historyByUserIdActions,
  historyByUserIdSelectors
} from "store/history/byUserId";
import { getHistoryByUserId } from "store/history/byUserId/thunk";
import { useAppDispatch, useAppSelector } from "store/store";
import { userSelectors } from "store/user";

export const PersonalActivity = () => {
  const dispatch = useAppDispatch();

  const { user } = useAppSelector(userSelectors.getState);
  const { history, isLoading, error, totalElements, page, size } =
    useAppSelector(historyByUserIdSelectors.getState);

  const getActivityHistoryById = useCallback(() => {
    user &&
      dispatch(
        getHistoryByUserId({
          id: user?.user_id
        })
      );
  }, [dispatch, user]);

  const onActivityHistoryPaginationChange = useCallback(
    (page: number, pageSize: number) => {
      if (pageSize === size) {
        dispatch(historyByUserIdActions.setPage(page));
      } else {
        dispatch(historyByUserIdActions.setPage(1));
        dispatch(historyByUserIdActions.setSize(pageSize));
      }
      getActivityHistoryById();
    },
    [dispatch, getActivityHistoryById, size]
  );

  useEffect(() => {
    user && getActivityHistoryById();
  }, [dispatch, getActivityHistoryById, user]);

  useEffect(() => {
    return () => {
      dispatch(historyByUserIdActions.clearState());
    };
  }, [dispatch]);

  return (
    <HistoryFooter
      history={history}
      isLoading={isLoading}
      error={error}
      noMargin
      isExtended
      withoutPerson
      pagination={{
        current: page,
        onChange: onActivityHistoryPaginationChange,
        pageSize: size,
        total: totalElements,
        showSizeChanger: true,
        pageSizeOptions: PAGE_SIZES
      }}
    />
  );
};
