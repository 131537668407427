import { createAsyncThunk } from "@reduxjs/toolkit";
import { historyByCabinetIdActions, historyByCabinetIdSelectors } from ".";
import * as api from "api/history";
import { RequestError } from "types";
import { serializeError } from "serialize-error";
import { RootState } from "../../store";
import { userSelectors } from "store/user";

export const getHistoryByCabinetId = createAsyncThunk<
  api.TGetHistoryByCabinetResponse,
  Pick<api.TGetHistoryByCabinetRequest, "id">,
  { state: RootState }
>(
  "history/getHistoryByCabinetId",
  (payload, { dispatch, rejectWithValue, getState }) => {
    dispatch(historyByCabinetIdActions.setLoading(true));
    dispatch(historyByCabinetIdActions.setError(null));

    const { page, size } = historyByCabinetIdSelectors.getState(getState());
    const { user } = userSelectors.getState(getState());

    if (!user) return Promise.reject();

    return api
      .getHistoryByCabinet({
        id: payload?.id,
        page: page - 1,
        size
      })
      .then(({ data }) => {
        dispatch(historyByCabinetIdActions.setHistory(data?.content));
        dispatch(
          historyByCabinetIdActions.setTotalElements(data?.totalElements)
        );
        return data;
      })
      .catch((err: RequestError) => {
        dispatch(historyByCabinetIdActions.setError(err));
        return rejectWithValue(serializeError(err));
      })
      .finally(() => {
        dispatch(historyByCabinetIdActions.setLoading(false));
      });
  }
);
