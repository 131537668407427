import React, { useCallback, useEffect } from "react";
import { HistoryFooter } from "components/common/redesign";
import { PAGE_SIZES } from "constants/pagination";
import { useAppDispatch, useAppSelector } from "store/store";
import { userSelectors } from "store/user";
import {
  historyByCabinetIdActions,
  historyByCabinetIdSelectors
} from "store/history/byCabinetId";
import { getHistoryByCabinetId } from "store/history/byCabinetId/thunk";

export const ColleaguesActivity = () => {
  const dispatch = useAppDispatch();

  const { user } = useAppSelector(userSelectors.getState);
  const { history, isLoading, error, totalElements, page, size } =
    useAppSelector(historyByCabinetIdSelectors.getState);

  const getActivityHistoryByCabinetId = useCallback(() => {
    user &&
      dispatch(
        getHistoryByCabinetId({
          id: user?.cabinet?.cabinet_id
        })
      );
  }, [dispatch, user]);

  const onActivityHistoryPaginationChange = useCallback(
    (page: number, pageSize: number) => {
      if (pageSize === size) {
        dispatch(historyByCabinetIdActions.setPage(page));
      } else {
        dispatch(historyByCabinetIdActions.setPage(1));
        dispatch(historyByCabinetIdActions.setSize(pageSize));
      }
      getActivityHistoryByCabinetId();
    },
    [dispatch, getActivityHistoryByCabinetId, size]
  );

  useEffect(() => {
    user && getActivityHistoryByCabinetId();
  }, [dispatch, getActivityHistoryByCabinetId, user]);

  useEffect(() => {
    return () => {
      dispatch(historyByCabinetIdActions.clearState());
    };
  }, [dispatch]);

  return (
    <HistoryFooter
      history={history}
      isLoading={isLoading}
      error={error}
      noMargin
      isExtended
      pagination={{
        current: page,
        onChange: onActivityHistoryPaginationChange,
        pageSize: size,
        total: totalElements,
        showSizeChanger: true,
        pageSizeOptions: PAGE_SIZES
      }}
    />
  );
};
