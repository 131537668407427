import React, { FC, useMemo } from "react";
import {
  Wrapper,
  DataBlock,
  DataTop,
  TitleBlock,
  CTABlock,
  DataBottom,
  StagesBlock,
  LinkButton
} from "./OfferHeader.styles";
import {
  LeftOutlined,
  EditOutlined,
  DownOutlined,
  MailFilled
} from "@ant-design/icons";
import {
  Button,
  Collapse,
  DescriptionsProps,
  Dropdown,
  MenuProps,
  Space,
  Typography
} from "antd";
import { DEVICE } from "constants/breakpoints";
import { useMediaQuery } from "react-responsive";
import { toDisplayDate } from "utils/dates";
import { projectRegionIdMapper } from "utils/mappers";
import { OfferTimeline } from "../OfferTimeline/OfferTimeline";
import {
  BasicTitle,
  DescriptionsCustom,
  OfferStatusTag,
  TOfferDataProps,
  TypographyTitleNoMargin
} from "components/common/redesign";
import { ErrorAlert } from "components/common/old";
import {
  offerUpdateForBuyerStatusOptions,
  offerUpdateForSellerStatusOptions
} from "constants/options";
import { Link } from "react-router-dom";

type TOfferHeaderProps = Omit<
  TOfferDataProps,
  "files" | "offers" | "getFiles" | "canEditFiles"
> & {
  onBuyerCompanyClick: () => void;
  onBuyerAccountClick: () => void;
  onSellerCompanyClick: () => void;
  onSellerAccountClick: () => void;
};

export const OfferHeader: FC<TOfferHeaderProps> = ({
  offer,
  application,
  buyerEntity,

  onGoBack,
  onEdit,
  withEdit,

  withStatusChange,
  onUpdateStatus,
  statusIsLoading,
  statusError,

  onBuyerCompanyClick,
  onBuyerAccountClick,
  onSellerCompanyClick,
  onSellerAccountClick,

  forSeller,
  previewOnly,
  onStepEdit,

  withMessages,

  statusesHistory
}) => {
  const isLG = useMediaQuery({ query: DEVICE.LG });
  const isMD = useMediaQuery({ query: DEVICE.MD });

  const statusOptions: MenuProps["items"] = useMemo(
    () =>
      withStatusChange
        ? (
            (forSeller
              ? offerUpdateForSellerStatusOptions
              : offerUpdateForBuyerStatusOptions)[offer?.status_id] || []
          ).map((status) => {
            return {
              key: status,
              label: <OfferStatusTag status_id={status} />
            };
          })
        : [],
    [forSeller, offer, withStatusChange]
  );

  const offerData: DescriptionsProps["items"] = useMemo(
    () => [
      ...(!previewOnly
        ? [
            {
              key: "create_ts",
              label: "Дата создания",
              children: toDisplayDate(offer?.create_ts)
            }
          ]
        : []),
      {
        key: "delivary_plan_ts",
        label: "Дата поставки",
        children: toDisplayDate(offer?.delivary_plan_ts)
      },
      {
        key: "seller_entity",
        label: "Юр. лицо поставщика",
        children: (
          <LinkButton onClick={onSellerCompanyClick} $accent>
            {offer?.seller_entity?.full_name}
          </LinkButton>
        )
      },
      ...(offer?.offer_comment
        ? [
            {
              key: "offer_comment",
              label: "Комментарий для покупателя",
              children: offer?.offer_comment
            }
          ]
        : []),
      ...(previewOnly
        ? [
            {
              key: "seller_account",
              label: "Счет поставщика",
              children: (
                <LinkButton onClick={onSellerAccountClick}>
                  {offer?.seller_account?.name}
                </LinkButton>
              )
            }
          ]
        : [])
    ],
    [offer, onSellerAccountClick, onSellerCompanyClick, previewOnly]
  );

  const applicationData: DescriptionsProps["items"] = useMemo(
    () => [
      {
        key: "application_delivery_due_timestamp",
        label: "Желаемая дата поставки",
        children: toDisplayDate(application?.application_delivery_due_timestamp)
      },
      {
        key: "entity_full_name",
        label: "Юр. лицо покупателя",
        children: (
          <LinkButton onClick={onBuyerCompanyClick} $accent>
            {buyerEntity?.full_name}
          </LinkButton>
        )
      },
      {
        key: "application_region_id",
        label: "Регион",
        children:
          (application &&
            projectRegionIdMapper[application?.application_region_id]
              ?.fullName) ||
          application?.application_region_id
      },
      {
        key: "is_need_delivery",
        label: "Необходима доставка",
        children: application?.is_need_delivery ? "Да" : "Нет"
      },
      ...(application?.is_need_delivery
        ? [
            {
              key: "location_id",
              label: "Адрес",
              children: application?.location?.address
            }
          ]
        : []),
      ...(application?.application_comment
        ? [
            {
              key: "application_comment",
              label: "Комментарий для поставщика",
              children: application?.application_comment
            }
          ]
        : [])
    ],
    [application, buyerEntity, onBuyerCompanyClick]
  );

  const paymentData: DescriptionsProps["items"] = useMemo(
    () => [
      {
        key: "seller_account_number",
        label: "Счет поставщика",
        children: (
          <LinkButton onClick={onSellerAccountClick}>
            {offer?.seller_account?.name}
          </LinkButton>
        )
      },
      {
        key: "buyer_account_number",
        label: "Счет покупателя",
        children: (
          <LinkButton onClick={onBuyerAccountClick}>
            {application?.buyer_account?.name}
          </LinkButton>
        )
      },
      {
        key: "is_postpayment",
        label: "Необходим отложенный платеж",
        children: application?.is_postpayment ? "Да" : "Нет"
      },
      ...(application?.is_postpayment
        ? [
            {
              key: "postpayment_days",
              label: "Кол-во дней отложенного платежа",
              children: `${application?.postpayment_days} дней`
            }
          ]
        : [])
    ],
    [application, offer, onBuyerAccountClick, onSellerAccountClick]
  );

  return (
    <Wrapper>
      <DataBlock>
        <DataTop>
          {!previewOnly && (
            <>
              <TitleBlock>
                <BasicTitle>
                  <Button
                    icon={<LeftOutlined />}
                    onClick={onGoBack}
                    type="text"
                  />
                  Предложение № {offer?.offer_id} от{" "}
                  {toDisplayDate(offer?.create_ts)}
                </BasicTitle>

                {!isMD && (withEdit || withMessages) && (
                  <CTABlock>
                    {withEdit && (
                      <Button
                        onClick={onEdit}
                        icon={<EditOutlined />}
                        size={!isLG ? undefined : "large"}
                      >
                        Редактировать
                      </Button>
                    )}
                    {withMessages && (
                      <Link
                        to={
                          forSeller
                            ? `../../messages/${application?.application_id}/${offer?.seller_entity.cabinet_id}`
                            : `../../applications/${application?.application_id}/messages/${application?.application_id}/${offer?.seller_entity?.cabinet_id}`
                        }
                      >
                        <Button
                          icon={<MailFilled />}
                          size={!isLG ? undefined : "large"}
                        >
                          Сообщения
                        </Button>
                      </Link>
                    )}
                  </CTABlock>
                )}

                {isLG &&
                  (!!statusOptions?.length ? (
                    <>
                      {/* dropdown с цветами статусов */}
                      <Dropdown
                        menu={{ items: statusOptions, onClick: onUpdateStatus }}
                      >
                        <Button
                          loading={statusIsLoading}
                          style={{ marginRight: "auto" }}
                          size="small"
                          type="text"
                        >
                          <Space>
                            <OfferStatusTag status_id={offer?.status_id} />
                            <DownOutlined />
                          </Space>
                        </Button>
                      </Dropdown>

                      <ErrorAlert error={statusError} />
                    </>
                  ) : (
                    <OfferStatusTag
                      status_id={offer?.status_id}
                      style={{ width: "fit-content" }}
                    />
                  ))}
              </TitleBlock>

              {isMD && (withEdit || withMessages) && (
                <CTABlock>
                  {withEdit && (
                    <Button
                      onClick={onEdit}
                      icon={<EditOutlined />}
                      size={!isLG ? undefined : "large"}
                    >
                      Редактировать
                    </Button>
                  )}
                  {withMessages && (
                    <Link
                      to={
                        forSeller
                          ? `../../messages/${application?.application_id}/${offer?.seller_entity?.cabinet_id}`
                          : `../../applications/${application?.application_id}/messages/${application?.application_id}/${offer?.seller_entity?.cabinet_id}`
                      }
                    >
                      <Button
                        icon={<MailFilled />}
                        size={!isLG ? undefined : "large"}
                      >
                        Сообщения
                      </Button>
                    </Link>
                  )}
                </CTABlock>
              )}
            </>
          )}

          <Typography.Text>
            Предложение создано для заявки №{" "}
            <Link to={`../../applications/${application?.application_id}`}>
              {application?.application_id} - {application?.application_name}
            </Link>
          </Typography.Text>

          {isLG && (
            <Collapse
              ghost
              items={[
                {
                  label: "Этапы предложения",
                  children: (
                    <OfferTimeline
                      status_id={offer?.status_id}
                      createTs={offer?.create_ts}
                      history={statusesHistory}
                    />
                  )
                }
              ]}
            />
          )}
        </DataTop>

        <DataBottom>
          {previewOnly && (
            <TypographyTitleNoMargin level={5}>
              <Button icon={<EditOutlined onClick={() => onStepEdit?.(0)} />} />
              Параметры предложения
            </TypographyTitleNoMargin>
          )}

          <DescriptionsCustom
            items={offerData}
            title={!previewOnly ? "Параметры предложения" : undefined}
          />
          {!previewOnly && (
            <>
              <DescriptionsCustom
                items={applicationData}
                title={"Параметры заявки"}
              />
              <DescriptionsCustom items={paymentData} title={"Оплата"} />
            </>
          )}
        </DataBottom>
      </DataBlock>
      {!isLG && (
        <StagesBlock>
          {!previewOnly &&
            (!!statusOptions?.length ? (
              <>
                <Dropdown
                  menu={{ items: statusOptions, onClick: onUpdateStatus }}
                >
                  <Button
                    loading={statusIsLoading}
                    style={{ marginLeft: "auto" }}
                    size="small"
                    type="text"
                  >
                    <Space>
                      <OfferStatusTag status_id={offer?.status_id} />
                      <DownOutlined />
                    </Space>
                  </Button>
                </Dropdown>

                <ErrorAlert error={statusError} />
              </>
            ) : (
              <OfferStatusTag status_id={offer?.status_id} />
            ))}

          <TypographyTitleNoMargin level={5}>
            Этапы предложения
          </TypographyTitleNoMargin>
          <OfferTimeline
            status_id={offer?.status_id}
            createTs={offer?.create_ts}
            history={statusesHistory}
            mode="right"
          />
        </StagesBlock>
      )}
    </Wrapper>
  );
};
