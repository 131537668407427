import React, { useCallback, useEffect, useMemo, useState } from "react";
import { AlertWrapper, ApplicationWrapper } from "./ApplicationForBuyer.styles";
import { useAppDispatch, useAppSelector } from "store/store";
import { useNavigate, useParams } from "react-router-dom";
import { accountsByCabinetIdSelectors } from "store/accounts/byCabinet";
import {
  ApplicationByIdActions,
  applicationByIdSelectors
} from "store/applications/byId";
import {
  applicationUpdateActions,
  applicationUpdateSelectors
} from "store/applications/update";
import {
  applicationUpdateStatusActions,
  applicationUpdateStatusSelectors
} from "store/applications/update_status";
import {
  approvementUpdateActions,
  approvementUpdateSelectors
} from "store/approvements/update";
import { colleaguesByCabinetSelectors } from "store/colleagues/byCabinet";
import {
  filesByObjectActions,
  filesByObjectSelectors
} from "store/files/byObject";
import {
  offersForBuyerByApplicationIdActions,
  offersForBuyerByApplicationIdSelectors
} from "store/offersForBuyer/byApplicationId";
import { projectsByCabinetSelectors } from "store/projects/byCabinet";
import { userSelectors } from "store/user";
import { Alert, Button } from "antd";
import { getApplicationById } from "store/applications/byId/thunk";
import { getProjectsByCabinet } from "store/projects/byCabinet/thunk";
import { getColleaguesByCabinet } from "store/colleagues/byCabinet/thunk";
import { getAccountsByCabinetId } from "store/accounts/byCabinet/thunk";
import {
  ECabinetRole,
  EFileType,
  EHistoryItemEntityType,
  TApplication
} from "types";
import { updateApplication } from "store/applications/update/thunk";
import { updateApplicationStatus } from "store/applications/update_status/thunk";
import { updateApprovement } from "store/approvements/update/thunk";
import { getOfferForBuyerByApplicationId } from "store/offersForBuyer/byApplicationId/thunk";
import { getFilesByObject } from "store/files/byObject/thunk";
import {
  ApplicationData,
  ApplicationEditForm
} from "components/common/redesign";
import { locationsByCabinetIdSelectors } from "store/locations/byCabinet";
import { getLocationsByCabinetId } from "store/locations/byCabinet/thunk";
import { getBestPricesFromOffers } from "utils/getBestPricesFromOffers";
import { getHistoryStatusesByTypeAndEntityId } from "store/history/statusesByTypeAndEntityId/thunk";
import {
  historyStatusesByTypeAndEntityIdActions,
  historyStatusesByTypeAndEntityIdSelectors
} from "store/history/statusesByTypeAndEntityId";
import {
  historyByTypeAndEntityIdActions,
  historyByTypeAndEntityIdSelectors
} from "store/history/byTypeAndEntityId";
import { getHistoryByTypeAndEntityId } from "store/history/byTypeAndEntityId/thunk";

export const ApplicationForBuyer = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [isEditing, setIsEditing] = useState(false);

  const { user, currentRole, isAdmin } = useAppSelector(userSelectors.getState);
  const { application } = useAppSelector(applicationByIdSelectors.getState);
  const { offersForBuyer } = useAppSelector(
    offersForBuyerByApplicationIdSelectors.getState
  );
  const { isLoading: updateIsLoading, error: updateError } = useAppSelector(
    applicationUpdateSelectors.getState
  );

  const { isLoading: statusIsLoading, error: statusError } = useAppSelector(
    applicationUpdateStatusSelectors.getState
  );
  const { isLoading: approveIsLoading, error: approveError } = useAppSelector(
    approvementUpdateSelectors.getState
  );
  const { projects } = useAppSelector(projectsByCabinetSelectors.getState);
  const { locations } = useAppSelector(locationsByCabinetIdSelectors.getState);
  const { accounts } = useAppSelector(accountsByCabinetIdSelectors.getState);
  const { users } = useAppSelector(colleaguesByCabinetSelectors.getState);
  const { files } = useAppSelector(filesByObjectSelectors.getState);
  const { history } = useAppSelector(
    historyStatusesByTypeAndEntityIdSelectors.getState
  );
  const { historyByType } = useAppSelector(
    historyByTypeAndEntityIdSelectors.getState
  );

  const { application_id } = useParams<{ application_id: string }>();

  const possibleApprovements = useMemo(() => {
    const openApprovements = application?.approvements?.filter(
      (approvement) => approvement?.approvement_status_id === 108
    );
    if (currentRole === ECabinetRole.BUYER) {
      if (user?.cabinet_role_id === 2) {
        return openApprovements;
      } else {
        return openApprovements?.filter(
          (approvement) => approvement?.approver?.user_id === user?.user_id
        );
      }
    }
    return [];
  }, [application, currentRole, user]);

  const project = useMemo(
    () =>
      projects?.find(
        (project) => project?.project_id === application?.project_id
      ),
    [application, projects]
  );

  const entity = useMemo(
    () =>
      user?.entities?.find(
        (entity) => entity?.entity?.entity_id === project?.entity_id
      ),
    [user, project]
  );

  const canUpdate = useMemo(
    () =>
      ((user?.cabinet_role_id === 2 ||
        entity?.role_id === 4 ||
        application?.creator?.user_id === user?.user_id ||
        application?.responsible?.user_id === user?.user_id) &&
        (application?.application_status_id === 92 ||
          application?.application_status_id === 93)) ||
      isAdmin,
    [
      application?.application_status_id,
      application?.creator?.user_id,
      application?.responsible?.user_id,
      entity?.role_id,
      isAdmin,
      user?.cabinet_role_id,
      user?.user_id
    ]
  );

  const entitiesForApplication = useMemo(
    () =>
      user?.entities
        ?.filter((entity) => entity?.role_id > 2)
        ?.map((entity) => entity?.entity),
    [user?.entities]
  );

  const canChangeStatus = useMemo(
    () =>
      user?.cabinet_role_id === 2 ||
      application?.creator?.user_id === user?.user_id ||
      application?.responsible?.user_id === user?.user_id ||
      isAdmin,
    [
      application?.creator?.user_id,
      application?.responsible?.user_id,
      isAdmin,
      user?.cabinet_role_id,
      user?.user_id
    ]
  );

  const canEditFiles = useMemo(
    () => !!entity?.role_id && entity?.role_id >= 2,
    [entity]
  );

  const bestPrices = useMemo(
    () => getBestPricesFromOffers(offersForBuyer),
    [offersForBuyer]
  );

  const historyProps = useMemo(() => {
    return application
      ? historyByType[EHistoryItemEntityType.APPLICATION]?.[
          application?.application_id
        ] || {}
      : {};
  }, [application, historyByType]);

  const getApplication = useCallback(
    (application_id: number) => {
      dispatch(getApplicationById(application_id));
    },
    [dispatch]
  );
  const getOffers = useCallback(
    (application_id: number) => {
      dispatch(getOfferForBuyerByApplicationId(application_id));
    },
    [dispatch]
  );
  const getFiles = useCallback(
    (application_id: number) => {
      dispatch(
        getFilesByObject({
          id: application_id,
          type: EFileType.APPLICATION
        })
      );
    },
    [dispatch]
  );
  const getProjects = useCallback(
    (cabinet_id: number) => {
      dispatch(getProjectsByCabinet(cabinet_id));
    },
    [dispatch]
  );
  const getLocations = useCallback(
    (cabinet_id: number) => {
      dispatch(getLocationsByCabinetId(cabinet_id));
    },
    [dispatch]
  );
  const getColleagues = useCallback(
    (cabinet_id: number) => {
      dispatch(getColleaguesByCabinet(cabinet_id));
    },
    [dispatch]
  );
  const getAccounts = useCallback(
    (cabinet_id: number) => {
      dispatch(getAccountsByCabinetId(cabinet_id));
    },
    [dispatch]
  );
  const getHistory = useCallback(
    (application_id: number) => {
      dispatch(
        getHistoryStatusesByTypeAndEntityId({
          id: application_id,
          type: EHistoryItemEntityType.APPLICATION
        })
      );
    },
    [dispatch]
  );

  const onEdit = useCallback(() => {
    setIsEditing(true);
  }, []);
  const onCancelEdit = useCallback(() => {
    setIsEditing(false);
  }, []);

  const onUpdate = useCallback(
    (values: TApplication) => {
      dispatch(
        updateApplication({
          ...application,
          ...values
        })
      )
        .unwrap()
        .then(() => {
          onCancelEdit();
          getApplication(Number(application_id));
        });
    },
    [application, application_id, dispatch, getApplication, onCancelEdit]
  );

  const onUpdateStatus = useCallback(
    ({ key }: { key: string }) => {
      application &&
        dispatch(
          updateApplicationStatus({
            application_id: application?.application_id,
            new_status_id: Number(key)
          })
        )
          .unwrap()
          .then(() => {
            getApplication(Number(application_id));
          });
    },
    [application, application_id, dispatch, getApplication]
  );
  const onApprove = useCallback(
    (approvement_status_id: number) => {
      possibleApprovements?.forEach((approvement) => {
        dispatch(
          updateApprovement({
            approvement_id: approvement?.approvement_id,
            approvement_status_id: approvement_status_id
          })
        )
          .unwrap()
          .then(() => {
            getApplication(Number(application_id));
          });
      });
    },
    [application_id, dispatch, getApplication, possibleApprovements]
  );

  const onGoBack = useCallback(() => {
    navigate("../");
  }, [navigate]);

  const getHistoryById = useCallback(() => {
    application &&
      dispatch(
        getHistoryByTypeAndEntityId({
          type: EHistoryItemEntityType.APPLICATION,
          id: application?.application_id,
          byEntity: true
        })
      );
  }, [application, dispatch]);

  useEffect(() => {
    application_id && getApplication(Number(application_id));
  }, [application_id, getApplication]);
  useEffect(() => {
    application && getOffers(application?.application_id);
  }, [application, getOffers]);
  useEffect(() => {
    application && getFiles(application?.application_id);
  }, [application, getFiles]);
  useEffect(() => {
    !projects && user && getProjects(user?.cabinet?.cabinet_id);
  }, [getProjects, projects, user]);
  useEffect(() => {
    !locations && user && getLocations(user?.cabinet?.cabinet_id);
  }, [getLocations, locations, user]);
  useEffect(() => {
    !users && user && getColleagues(user?.cabinet?.cabinet_id);
  }, [getColleagues, user, users]);
  useEffect(() => {
    !accounts && user && getAccounts(user?.cabinet?.cabinet_id);
  }, [accounts, getAccounts, user]);
  useEffect(() => {
    application && getHistory(application?.application_id);
  }, [application, getHistory]);

  useEffect(() => {
    return () => {
      dispatch(applicationUpdateStatusActions.clearState());
      dispatch(applicationUpdateActions.clearState());
      dispatch(ApplicationByIdActions.clearState());
      dispatch(approvementUpdateActions.clearState());
      dispatch(offersForBuyerByApplicationIdActions.clearState());
      dispatch(filesByObjectActions.clearState());
      dispatch(historyStatusesByTypeAndEntityIdActions.clearState());
      dispatch(
        historyByTypeAndEntityIdActions.clearStateByType(
          EHistoryItemEntityType.APPLICATION
        )
      );
    };
  }, [dispatch]);

  return application ? (
    isEditing ? (
      <ApplicationEditForm
        application={application}
        user={user}
        users={users}
        projects={projects}
        accounts={accounts}
        entities={entitiesForApplication}
        locations={locations}
        onSubmit={onUpdate}
        isLoading={updateIsLoading}
        error={updateError}
        onCancel={onCancelEdit}
      />
    ) : (
      <ApplicationWrapper>
        <ApplicationData
          application={application}
          files={files}
          getFiles={() => getFiles(application?.application_id)}
          canEditFiles={canEditFiles}
          offers={offersForBuyer}
          entity={entity?.entity}
          project={project}
          offersLength={offersForBuyer?.length}
          onEdit={onEdit}
          onGoBack={onGoBack}
          withEdit={!!canUpdate}
          onApprove={onApprove}
          hasApprovements={!!possibleApprovements?.length}
          approvementIsLoading={approveIsLoading}
          approvementError={approveError}
          withStatusChange={canChangeStatus}
          onUpdateStatus={onUpdateStatus}
          statusIsLoading={statusIsLoading}
          statusError={statusError}
          withMessages
          bestPrices={bestPrices}
          colleagues={users}
          statusesHistory={history}
          onGetHistoryClick={getHistoryById}
          historyProps={historyProps}
        />
      </ApplicationWrapper>
    )
  ) : (
    <AlertWrapper>
      <Alert message="Данные по заявке отсутствуют" showIcon />

      <Button onClick={onGoBack}>Назад</Button>
    </AlertWrapper>
  );
};
