import styled from "@emotion/styled";
import { Typography } from "antd";
import { DEVICE } from "constants/breakpoints";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  flex: 1;
`;

export const TimeStyled = styled(Typography.Text)`
  width: 140px;
  margin-right: 8px;

  @media ${DEVICE.MD} {
    width: 80px;
  }
`;
