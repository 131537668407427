import React, { FC, useMemo } from "react";
import {
  AimOutlined,
  CheckOutlined,
  ClockCircleOutlined,
  CloseOutlined
} from "@ant-design/icons";
import { Timeline, TimelineProps, Typography } from "antd";
import {
  StageTextBlock,
  StyledStageText,
  timelineColor
} from "./ApplicationTimeline.styles";
import { applicationStatusIdMapper } from "utils/mappers";
import { THistoryItem } from "types";
import { toDisplayDateTime } from "utils/dates";

type TApplicationTimelineProps = {
  status_id: number;
  mode?: TimelineProps["mode"];
  createTs?: number;
  history?: THistoryItem[];
};

export const ApplicationTimeline: FC<TApplicationTimelineProps> = ({
  status_id,
  mode,
  createTs,
  history
}) => {
  const historyTimes = useMemo(
    () =>
      history?.reduce((acc: Record<number, number>, item) => {
        const prevValue = acc[item?.status as number];
        const newValue = item?.modifiedAt;

        return {
          ...acc,
          [item?.status || 0]: prevValue
            ? Math.max(prevValue, newValue)
            : item?.modifiedAt
        };
      }, {}),
    [history]
  );
  return (
    <Timeline
      style={{ minWidth: "252px" }}
      mode={mode}
      items={
        status_id === 122
          ? [
              {
                children: (
                  <StageTextBlock>
                    <StyledStageText $color={timelineColor.ERROR}>
                      {applicationStatusIdMapper[122]?.shortName}
                    </StyledStageText>

                    <Typography.Text type="secondary">
                      {toDisplayDateTime(historyTimes?.[122])}
                    </Typography.Text>
                  </StageTextBlock>
                ),
                color: timelineColor.ERROR,
                dot: <CloseOutlined />
              }
            ]
          : [
              {
                children: (
                  <StageTextBlock>
                    <StyledStageText
                      $color={
                        status_id === 92
                          ? timelineColor?.DEFAULT
                          : status_id > 92
                            ? timelineColor.ACTIVE
                            : timelineColor.MUTED
                      }
                    >
                      {applicationStatusIdMapper[92]?.shortName}
                    </StyledStageText>

                    <Typography.Text type="secondary">
                      {toDisplayDateTime(historyTimes?.[92] || createTs)}
                    </Typography.Text>
                  </StageTextBlock>
                ),
                color:
                  status_id >= 92 ? timelineColor.ACTIVE : timelineColor.MUTED,
                dot:
                  status_id === 92 ? (
                    <AimOutlined />
                  ) : status_id > 92 ? (
                    <CheckOutlined />
                  ) : (
                    <ClockCircleOutlined />
                  )
              },
              {
                children: (
                  <StageTextBlock>
                    <StyledStageText
                      $color={
                        status_id === 93
                          ? timelineColor?.DEFAULT
                          : status_id > 93
                            ? timelineColor.ACTIVE
                            : timelineColor.MUTED
                      }
                    >
                      {applicationStatusIdMapper[93]?.shortName}
                    </StyledStageText>

                    <Typography.Text type="secondary">
                      {toDisplayDateTime(historyTimes?.[93])}
                    </Typography.Text>
                  </StageTextBlock>
                ),
                color:
                  status_id >= 93 ? timelineColor.ACTIVE : timelineColor.MUTED,
                dot:
                  status_id === 93 ? (
                    <AimOutlined />
                  ) : status_id > 93 ? (
                    <CheckOutlined />
                  ) : (
                    <ClockCircleOutlined />
                  )
              },
              ...(status_id === 95
                ? [
                    {
                      children: (
                        <StageTextBlock>
                          <StyledStageText $color={timelineColor.ERROR}>
                            {applicationStatusIdMapper[95]?.shortName}
                          </StyledStageText>

                          <Typography.Text type="secondary">
                            {toDisplayDateTime(historyTimes?.[95])}
                          </Typography.Text>
                        </StageTextBlock>
                      ),
                      color: timelineColor.ERROR,
                      dot: <CloseOutlined />
                    }
                  ]
                : [
                    {
                      children: (
                        <StageTextBlock>
                          <StyledStageText
                            $color={
                              status_id === 94
                                ? timelineColor?.DEFAULT
                                : status_id > 94
                                  ? timelineColor.ACTIVE
                                  : timelineColor.MUTED
                            }
                          >
                            {applicationStatusIdMapper[94]?.shortName}
                          </StyledStageText>

                          <Typography.Text type="secondary">
                            {toDisplayDateTime(historyTimes?.[94])}
                          </Typography.Text>
                        </StageTextBlock>
                      ),
                      color:
                        status_id >= 94
                          ? timelineColor.ACTIVE
                          : timelineColor.MUTED,
                      dot:
                        status_id === 94 ? (
                          <AimOutlined />
                        ) : status_id > 94 ? (
                          <CheckOutlined />
                        ) : (
                          <ClockCircleOutlined />
                        )
                    }
                  ]),
              ...(status_id === 95
                ? []
                : [
                    {
                      children: (
                        <StageTextBlock>
                          <StyledStageText
                            $color={
                              status_id === 96
                                ? timelineColor?.DEFAULT
                                : status_id > 96
                                  ? timelineColor.ACTIVE
                                  : timelineColor.MUTED
                            }
                          >
                            {applicationStatusIdMapper[96]?.shortName}
                          </StyledStageText>

                          <Typography.Text type="secondary">
                            {toDisplayDateTime(historyTimes?.[96])}
                          </Typography.Text>
                        </StageTextBlock>
                      ),
                      color:
                        status_id >= 96
                          ? timelineColor.ACTIVE
                          : timelineColor.MUTED,
                      dot:
                        status_id === 96 ? (
                          <AimOutlined />
                        ) : status_id > 96 ? (
                          <CheckOutlined />
                        ) : (
                          <ClockCircleOutlined />
                        )
                    },
                    {
                      children: (
                        <StageTextBlock>
                          <StyledStageText
                            $color={
                              status_id === 121
                                ? timelineColor?.DEFAULT
                                : status_id > 96
                                  ? timelineColor.ACTIVE
                                  : timelineColor.MUTED
                            }
                          >
                            {applicationStatusIdMapper[121]?.shortName}
                          </StyledStageText>

                          <Typography.Text type="secondary">
                            {toDisplayDateTime(historyTimes?.[121])}
                          </Typography.Text>
                        </StageTextBlock>
                      ),
                      color:
                        status_id > 96
                          ? timelineColor.ACTIVE
                          : timelineColor.MUTED,
                      dot:
                        status_id === 121 ? (
                          <AimOutlined />
                        ) : status_id > 96 ? (
                          <CheckOutlined />
                        ) : (
                          <ClockCircleOutlined />
                        )
                    },
                    ...(status_id === 98
                      ? [
                          {
                            children: (
                              <StageTextBlock>
                                <StyledStageText $color={timelineColor.ERROR}>
                                  {applicationStatusIdMapper[98]?.shortName}
                                </StyledStageText>

                                <Typography.Text type="secondary">
                                  {toDisplayDateTime(historyTimes?.[98])}
                                </Typography.Text>
                              </StageTextBlock>
                            ),
                            color: timelineColor.ERROR,
                            dot: <CloseOutlined />
                          }
                        ]
                      : [
                          {
                            children: (
                              <StageTextBlock>
                                <StyledStageText
                                  $color={
                                    status_id === 97
                                      ? timelineColor.ACTIVE
                                      : timelineColor.MUTED
                                  }
                                >
                                  {applicationStatusIdMapper[97]?.shortName}
                                </StyledStageText>

                                <Typography.Text type="secondary">
                                  {toDisplayDateTime(historyTimes?.[97])}
                                </Typography.Text>
                              </StageTextBlock>
                            ),
                            color:
                              status_id === 97
                                ? timelineColor.ACTIVE
                                : timelineColor.MUTED,
                            dot:
                              status_id === 97 ? (
                                <CheckOutlined />
                              ) : (
                                <ClockCircleOutlined />
                              )
                          }
                        ])
                  ])
            ]
      }
    />
  );
};
