import { PageableResponse, THistoryItem } from "types";
import { RequestPromise, apiRequest } from "utils/api";

export const getHistoryByCabinet = (
  data: TGetHistoryByCabinetRequest
): RequestPromise<TGetHistoryByCabinetResponse> => {
  const { id, ...params } = data;
  return apiRequest.get(`/history/cabinet/${id}`, {
    params
  });
};

export type TGetHistoryByCabinetRequest = {
  id: number;
  size: number;
  page: number;
};

export type TGetHistoryByCabinetResponse = PageableResponse<THistoryItem>;
