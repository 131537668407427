import styled from "@emotion/styled";
import { Button, Form, Input } from "antd";
import { DEVICE } from "constants/breakpoints";

export const SearchRowForm = styled(Form)`
  width: 100%;
  display: flex;
  gap: 8px;
  max-width: 624px;
  flex-wrap: wrap;

  @media ${DEVICE.LG} {
    max-width: 660px;
  }
  @media ${DEVICE.MD} {
    flex-direction: column;
  }
`;
export const IdInput = styled(Input)`
  flex: 5;
`;
export const FiltersBlock = styled.div`
  display: flex;
  gap: 8px;
  flex: 6;
`;
export const SearchButton = styled(Button)`
  flex: 1;
`;
export const FiltersButton = styled(Button)``;

export const FiltersContainer = styled.div`
  width: 616px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-width: calc(100vw - 152px);

  @media ${DEVICE.SM} {
    max-width: calc(100vw - 112px);
  }
`;
