import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { THistoryByUserIdState } from "./types";

import { RequestError, THistoryItem } from "types";
import { DEFAULT_PAGE_SIZE } from "constants/pagination";

const initialState: THistoryByUserIdState = {
  isLoading: false,
  error: null,
  history: undefined,
  totalElements: undefined,
  page: 1,
  size: DEFAULT_PAGE_SIZE
};

export const HistoryByUserIdSlice = createSlice({
  name: "history/byUserId",
  initialState,
  reducers: {
    clearState: () => initialState,
    setHistory: (state, { payload }: PayloadAction<THistoryItem[]>) => {
      state.history = payload;
    },
    setTotalElements: (state, { payload }: PayloadAction<number>) => {
      state.totalElements = payload;
    },
    setPage: (state, { payload }: PayloadAction<number>) => {
      state.page = payload;
    },
    setSize: (state, { payload }: PayloadAction<number>) => {
      state.size = payload;
    },
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
    setError: (state, { payload }: PayloadAction<RequestError | null>) => {
      state.error = payload;
    }
  }
});

export const historyByUserIdActions = HistoryByUserIdSlice.actions;
export const historyByUserIdReducer = HistoryByUserIdSlice.reducer;

export * as historyByUserIdSelectors from "./selectors";
