import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { THistoryByTypeAndEntityIdState } from "./types";

import { EHistoryItemEntityType, RequestError, THistoryItem } from "types";

const initialState: THistoryByTypeAndEntityIdState = {
  isLoading: false,
  error: null,
  history: undefined,
  historyByType: {}
};

export const HistoryByTypeAndEntityIdSlice = createSlice({
  name: "history/byTypeAndEntityId",
  initialState,
  reducers: {
    clearState: () => initialState,
    setHistory: (state, { payload }: PayloadAction<THistoryItem[]>) => {
      state.history = payload;
    },
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
    setError: (state, { payload }: PayloadAction<RequestError | null>) => {
      state.error = payload;
    },

    clearStateByType: (
      state,
      { payload }: PayloadAction<EHistoryItemEntityType>
    ) => {
      state.historyByType[payload] = undefined;
    },
    prepareStoreByTypeId: (
      state,
      {
        payload
      }: PayloadAction<{
        type: EHistoryItemEntityType;
        id: number;
      }>
    ) => {
      if (!state.historyByType[payload.type]) {
        state.historyByType[payload.type] = {};
      }
      if (!state.historyByType[payload.type]![payload.id]) {
        state.historyByType[payload.type]![payload.id] = {
          isLoading: false,
          error: null,
          history: undefined
        };
      }
    },
    setHistoryByTypeId: (
      state,
      {
        payload
      }: PayloadAction<{
        type: EHistoryItemEntityType;
        id: number;
        history: THistoryItem[];
      }>
    ) => {
      state.historyByType[payload.type]![payload.id].history = payload.history;
    },
    setLoadingByTypeId: (
      state,
      {
        payload
      }: PayloadAction<{
        type: EHistoryItemEntityType;
        id: number;
        isLoading: boolean;
      }>
    ) => {
      state.historyByType[payload.type]![payload.id].isLoading =
        payload.isLoading;
    },
    setErrorByTypeId: (
      state,
      {
        payload
      }: PayloadAction<{
        type: EHistoryItemEntityType;
        id: number;
        error: RequestError | null;
      }>
    ) => {
      state.historyByType[payload.type]![payload.id].error = payload.error;
    }
  }
});

export const historyByTypeAndEntityIdActions =
  HistoryByTypeAndEntityIdSlice.actions;
export const historyByTypeAndEntityIdReducer =
  HistoryByTypeAndEntityIdSlice.reducer;

export * as historyByTypeAndEntityIdSelectors from "./selectors";
