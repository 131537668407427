import React, { FC, useMemo } from "react";
import { TLocation } from "types";
import { Button, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";

type TLocationsTableProps = {
  locations?: TLocation[];
  onDelete?: (id: number) => void;
  onEdit?: (id: number) => void;
  isDeleting?: boolean;
};

export const LocationsTable: FC<TLocationsTableProps> = ({
  locations,
  onDelete,
  onEdit,
  isDeleting
}) => {
  const columns: ColumnsType<TLocation> = useMemo(
    () => [
      {
        title: "Название",
        dataIndex: "title"
      },
      {
        title: "Адрес",
        dataIndex: "address"
      },
      {
        key: "edit",
        render: (value: TLocation) => (
          <Button
            onClick={() => onEdit?.(value?.location_id)}
            icon={<EditOutlined />}
          >
            Редактировать
          </Button>
        )
      },
      {
        key: "delete",
        render: (value: TLocation) => (
          <Button
            onClick={() => onDelete?.(value?.location_id)}
            icon={<DeleteOutlined />}
            danger
            loading={isDeleting}
          >
            Удалить
          </Button>
        )
      }
    ],
    [isDeleting, onDelete, onEdit]
  );

  return (
    <Table
      dataSource={locations}
      columns={columns}
      pagination={false}
      scroll={{ x: "1000px" }}
    />
  );
};
