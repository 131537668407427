import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Typography } from "antd";

export enum ETTimelineColor {
  ACTIVE = "ACTIVE",
  MUTED = "MUTED",
  ERROR = "ERROR",
  DEFAULT = "DEFAULT"
}

export const timelineColor: Record<ETTimelineColor, string> = {
  ACTIVE: "#3480fc",
  MUTED: "#8c8c8c",
  ERROR: "#f5222d",
  DEFAULT: "#262626"
};

export const StageTextBlock = styled.div`
  width: 100%;
`;

export const StyledStageText = styled(Typography.Text)<{
  $color: string;
}>`
  display: block;
  /* width: 100%; */

  ${({ $color }) => css`
    color: ${$color} !important;
  `}
`;
