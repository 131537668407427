import { TPersonEntity } from "types";

// const prevMapper = {
//   0: "Нет доступа",
//   1: "Наблюдающий",
//   2: "Сотрудник",
//   3: "Менеджер",
//   4: "Владелец"
// };

export const entityRoleIdBuyerMapper: Record<
  TPersonEntity["role_id"],
  { name: string; description: string }
> = {
  0: {
    name: "Нет доступа",
    description:
      "сотрудник не сможет просматривать объекты, связанные с данным юридическим лицом."
  },
  1: {
    name: "Контролер",
    description: "имеет доступ к просмотру данных компании и заявок."
  },
  2: {
    name: "Рядовой сотрудник",
    description: "может создавать заявки на закупки."
  },
  3: {
    name: "Менеджер по снабжению",
    description:
      "уполномочен согласовывать заявки рядовых сотрудников и предложения поставщиков."
  },
  4: {
    name: "Руководитель",
    description:
      "может управлять пользователями кабинета, добавлять и изменять их, управлять связанными юридическими лицами, а также согласовывать или отклонять счета менеджеров по снабжению."
  }
};
export const entityRoleIdSellerMapper: Record<
  TPersonEntity["role_id"],
  { name: string; description: string }
> = {
  0: {
    name: "Нет доступа",
    description:
      "сотрудник не сможет просматривать объекты, связанные с данным юридическим лицом."
  },
  1: {
    name: "Логист",
    description: "имеет доступ к просмотру данных компании и заявок."
  },
  2: {
    name: "Менеджер по продажам",
    description: "может делать предложения по заявкам покупателей."
  },
  3: {
    name: "Руководитель",
    description:
      "уполномочен согласовывать цены по заявкам от менеджеров по продажам."
  },
  4: {
    name: "Директор",
    description:
      "может управлять пользователями кабинета, добавлять и изменять их, управлять связанными юридическими лицами."
  }
};
