import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { TLocationDeleteState } from "./types";

import { RequestError, TLocation } from "types";

const initialState: TLocationDeleteState = {
  isLoading: false,
  error: null,
  location: undefined
};

export const LocationDeleteSlice = createSlice({
  name: "location/delete",
  initialState,
  reducers: {
    clearState: () => initialState,
    setLocation: (state, { payload }: PayloadAction<TLocation>) => {
      state.location = payload;
    },
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
    setError: (state, { payload }: PayloadAction<RequestError | null>) => {
      state.error = payload;
    }
  }
});

export const locationDeleteActions = LocationDeleteSlice.actions;
export const locationDeleteReducer = LocationDeleteSlice.reducer;

export * as locationDeleteSelectors from "./selectors";
