import React, { FC, ReactNode, useMemo } from "react";
import { TApplicationPosition, TPricesByPositionMod } from "types";
import Table, { ColumnsType } from "antd/es/table";
import {
  applicationPositionCategoryIdMapper,
  applicationPositionUnitTypeIdMapper
} from "utils/mappers";
import {
  TableWrapper,
  TableScrollWrapper,
  PricesBlock
} from "./ApplicationPositionsTable.styles";
import { Empty, TypographyTitleNoMargin } from "components/common/redesign";
import {
  CheckCircleOutlined,
  EditOutlined,
  WarningOutlined
} from "@ant-design/icons";
import { Button, Tooltip, Typography } from "antd";
import { padFractionalZeros, splitThousands } from "utils/numbers";
import { Link } from "react-router-dom";

type TApplicationPositionsTableProps = {
  positions?: TApplicationPosition[];
  onStepEdit?: (step: number) => void;
  icon?: ReactNode;
  onIconClick?: (position: TApplicationPosition) => void;
  bestPrices?: TPricesByPositionMod;
};

export const ApplicationPositionsTable: FC<TApplicationPositionsTableProps> = ({
  positions,
  onStepEdit,
  icon,
  onIconClick,
  bestPrices
}) => {
  const positionsColumns: ColumnsType<TApplicationPosition> = useMemo(
    () => [
      {
        title: "№",
        key: "index",
        render: (value, record, index) => index + 1,
        minWidth: 64
      },
      {
        title: "Товарная категория",
        dataIndex: "category_id",
        render: (value: number) =>
          applicationPositionCategoryIdMapper[value] || value,
        minWidth: 288
      },
      {
        title: "Наименование",
        dataIndex: "position_name",
        minWidth: 296
      },
      {
        title: "Кол-во",
        key: "unit_amount",
        render: (value: TApplicationPosition) =>
          [
            value?.unit_amount?.toString()?.replaceAll(".", ","),
            applicationPositionUnitTypeIdMapper[value?.unit_type_id]
              ?.shortName || value?.unit_type_id
          ].join(" "),
        minWidth: 136
      },
      {
        title: "Комментарий",
        dataIndex: "position_comment",
        minWidth: 165
      },
      ...(bestPrices
        ? [
            {
              title: "Лучшие цены",
              key: "best_prices",
              render: (value: TApplicationPosition) => {
                if (!value?.position_id) {
                  return <></>;
                }

                const origPrice = bestPrices?.[value?.position_id]?.original;
                const analoguePrice =
                  bestPrices?.[value?.position_id]?.analogue;

                const originalText =
                  origPrice?.price !== undefined
                    ? `${splitThousands(padFractionalZeros(origPrice?.price))} руб.`
                    : "-";
                const originalIcon = origPrice?.fromOwner ? (
                  <>
                    {" "}
                    <Tooltip title="Моя цена">
                      <CheckCircleOutlined />
                    </Tooltip>
                  </>
                ) : null;

                const analogueText =
                  analoguePrice?.price !== undefined
                    ? `${splitThousands(padFractionalZeros(analoguePrice?.price))} руб.`
                    : "-";
                const analogueIcons = (
                  <>
                    {analoguePrice?.fromOwner && (
                      <>
                        {" "}
                        <Tooltip title="Моя цена">
                          <CheckCircleOutlined />
                        </Tooltip>
                      </>
                    )}{" "}
                    <Typography.Text>
                      <Tooltip title="Аналог">
                        <WarningOutlined />
                      </Tooltip>
                    </Typography.Text>
                  </>
                );

                return (
                  <PricesBlock>
                    {origPrice?.offerId ? (
                      <Typography.Text strong>
                        <Link to={`../../offers/${origPrice?.offerId}`}>
                          {originalText}
                        </Link>
                        {originalIcon}
                      </Typography.Text>
                    ) : (
                      <Typography.Text>
                        {originalText}
                        {originalIcon}
                      </Typography.Text>
                    )}
                    {analoguePrice?.offerId ? (
                      <Typography.Text>
                        <Link to={`../../offers/${origPrice?.offerId}`}>
                          {analogueText}
                        </Link>
                        {analogueIcons}
                      </Typography.Text>
                    ) : (
                      <Typography.Text type="secondary">
                        {analogueText}
                        {analogueIcons}
                      </Typography.Text>
                    )}
                  </PricesBlock>
                );
              },
              minWidth: 220
            }
          ]
        : []),
      ...(icon
        ? [
            {
              title: "",
              key: "icon",
              render: (value: TApplicationPosition) => (
                <Button icon={icon} onClick={() => onIconClick?.(value)} />
              ),
              minWidth: 64
            }
          ]
        : [])
    ],
    [bestPrices, icon, onIconClick]
  );

  return (
    <TableWrapper>
      <TypographyTitleNoMargin level={5}>
        {onStepEdit && (
          <Button icon={<EditOutlined onClick={() => onStepEdit?.(1)} />} />
        )}
        Позиции заявки
      </TypographyTitleNoMargin>

      {positions?.length ? (
        <TableScrollWrapper>
          <Table
            dataSource={positions}
            columns={positionsColumns}
            pagination={false}
            bordered
          />
        </TableScrollWrapper>
      ) : (
        <Empty description="Пока нет ни одной позиции" />
      )}
    </TableWrapper>
  );
};
