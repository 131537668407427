import { combineReducers } from "@reduxjs/toolkit";
import { historyByCabinetIdReducer } from "./byCabinetId";
import { historyByTypeAndEntityIdReducer } from "./byTypeAndEntityId";
import { historyStatusesByTypeAndEntityIdReducer } from "./statusesByTypeAndEntityId";
import { historyByUserIdReducer } from "./byUserId";

export const historyReducer = combineReducers({
  byCabinetId: historyByCabinetIdReducer,
  byUserId: historyByUserIdReducer,
  byTypeAndEntityId: historyByTypeAndEntityIdReducer,
  statusesByTypeAndEntityId: historyStatusesByTypeAndEntityIdReducer
});
