import React, { FC, useMemo } from "react";

import { Button, Form, Select, Typography } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { TEntity } from "types";
import {
  entityRoleIdBuyerOptions,
  entityRoleIdSellerOptions
} from "constants/options";

type TEntitiesSelectorProps = {
  entities: TEntity[];
  isSeller: boolean;
};

export const EntitiesSelector: FC<TEntitiesSelectorProps> = ({
  entities,
  isSeller
}) => {
  const entitiesOptions = useMemo(
    () =>
      entities?.map((entity) => ({
        label: entity?.short_name,
        value: entity?.entity_id
      })),
    [entities]
  );

  return (
    <>
      <Typography.Text style={{ margin: "0 0 8px", display: "block" }}>
        Юр. лица
      </Typography.Text>
      <Form.List name="entities">
        {(fields, { add, remove }) => {
          return (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <div
                  key={key}
                  style={{
                    display: "flex",
                    gap: 8,
                    alignItems: "baseline",
                    flexWrap: "wrap",
                    marginBottom: 24
                  }}
                >
                  <Form.Item
                    {...restField}
                    name={[name, "entity"]}
                    style={{
                      flex: 1,
                      marginBottom: 0,
                      width: "100%",
                      minWidth: 200
                    }}
                    rules={[
                      {
                        required: true,
                        message: "Выберите юр. лицо"
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (
                            (
                              getFieldValue("entities") as {
                                entity: string;
                                role_id: string;
                              }[]
                            ).filter((item) => item.entity === value).length !==
                            1
                          ) {
                            return Promise.reject(
                              new Error("Юр. лицо выбрано 2 раза")
                            );
                          } else {
                            return Promise.resolve();
                          }
                        }
                      })
                    ]}
                  >
                    <Select options={entitiesOptions} />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, "role_id"]}
                    style={{ flex: 2, marginBottom: 0, width: "100%" }}
                    rules={[
                      {
                        required: true,
                        message: "Выберите роль"
                      }
                    ]}
                  >
                    <Select
                      options={
                        isSeller
                          ? entityRoleIdSellerOptions
                          : entityRoleIdBuyerOptions
                      }
                    />
                  </Form.Item>
                  <MinusCircleOutlined onClick={() => remove(name)} />
                </div>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined />}
                >
                  Добавить юр. лицо
                </Button>
              </Form.Item>
            </>
          );
        }}
      </Form.List>
    </>
  );
};
