import {
  entityRoleIdBuyerMapper,
  entityRoleIdSellerMapper
} from "utils/mappers";

export const entityRoleIdBuyerOptions = Object.keys(entityRoleIdBuyerMapper)
  .map(Number)
  .map((item) => ({
    value: item,
    label: entityRoleIdBuyerMapper[item]?.name
  }));
export const entityRoleIdSellerOptions = Object.keys(entityRoleIdSellerMapper)
  .map(Number)
  .map((item) => ({
    value: item,
    label: entityRoleIdSellerMapper[item]?.name
  }));
