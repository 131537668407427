import { createAsyncThunk } from "@reduxjs/toolkit";
import { locationAddActions } from "./add";
import { locationByProjectIdActions } from "./byProject";
import { locationsByEntityIdActions } from "./byEntity";
import { locationUpdateActions } from "./update";
import { locationsByCabinetIdActions } from "./byCabinet";
import { locationDeleteActions } from "./delete";

export const clearLocations = createAsyncThunk<void, void>(
  "locations/clearLocations",
  (payload, { dispatch, rejectWithValue }) => {
    dispatch(locationAddActions.clearState());
    dispatch(locationByProjectIdActions.clearState());
    dispatch(locationsByEntityIdActions.clearState());
    dispatch(locationUpdateActions.clearState());
    dispatch(locationsByCabinetIdActions.clearState());
    dispatch(locationDeleteActions.clearState());
  }
);
