import { THistoryItem } from "types";

export const historyActionMapper: Record<
  THistoryItem["action"],
  { noun: string; verb: string }
> = {
  CREATE: { noun: "Создание", verb: "создал(а)" },
  UPDATE: { noun: "Обновление", verb: "обновил(а)" },
  UPDATE_STATUS: { noun: "Обновление статуса", verb: "обновил(а) статус" },
  DELETE: { noun: "Удаление", verb: "удалил(а)" },
  SOFT_DELETE: { noun: "Пометка удаленным", verb: "пометил(а) удаленным" }
};
