import axios, { AxiosPromise, AxiosRequestConfig } from "axios";
import { store } from "store/store";
import { logout } from "store/user/logout/thunk";

axios.defaults.withCredentials = true;

const apiEndpoint =
  process.env.NODE_ENV === "development"
    ? "/api"
    : process.env.REACT_APP_API_URL;

export const mainAxios = axios.create({ baseURL: apiEndpoint });

mainAxios.interceptors.response.use(
  (response) => response,
  (err) => {
    if (
      err?.response &&
      err?.response?.status === 401 &&
      err?.config?.url !== "/users/get"
    ) {
      store.dispatch(logout({ withoutRequest: true }));
    }

    return Promise.reject(err);
  }
);

export const apiRequest = {
  get: (url: string, config?: AxiosRequestConfig): AxiosPromise =>
    mainAxios.get(url, config),
  post: (
    url: string,
    body?: Record<string, unknown> | FormData,
    config?: AxiosRequestConfig
  ): AxiosPromise => mainAxios.post(url, body, config)
};

export type RequestPromise<T> = AxiosPromise<T>;
export type RequestConfig<T> = AxiosRequestConfig<T>;
