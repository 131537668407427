import { createAsyncThunk } from "@reduxjs/toolkit";
import { historyStatusesByTypeAndEntityIdActions } from ".";
import * as api from "api/history";
import { RequestError } from "types";
import { serializeError } from "serialize-error";
import { RootState } from "../../store";

export const getHistoryStatusesByTypeAndEntityId = createAsyncThunk<
  api.TGetHistoryStatusesByTypeAndEntityIdResponse,
  api.TGetHistoryStatusesByTypeAndEntityIdRequest,
  { state: RootState }
>(
  "history/getHistoryStatusesByTypeAndEntityId",
  (payload, { dispatch, rejectWithValue, getState }) => {
    dispatch(historyStatusesByTypeAndEntityIdActions.setLoading(true));
    dispatch(historyStatusesByTypeAndEntityIdActions.setError(null));

    return api
      .getHistoryStatusesByTypeAndEntityId(payload)
      .then(({ data }) => {
        dispatch(historyStatusesByTypeAndEntityIdActions.setHistory(data));

        return data;
      })
      .catch((err: RequestError) => {
        dispatch(historyStatusesByTypeAndEntityIdActions.setError(err));
        return rejectWithValue(serializeError(err));
      })
      .finally(() => {
        dispatch(historyStatusesByTypeAndEntityIdActions.setLoading(false));
      });
  }
);
