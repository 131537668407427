import React, { FC, useCallback, useState } from "react";
import { Wrapper } from "./HistoryFooter.styles";
import { Button, Pagination, TablePaginationConfig } from "antd";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { RequestError, THistoryItem } from "types";
import { ErrorAlert } from "components/common/old";
import { HistoryList } from "../HistoryList/HistoryList";

type THistoryFooterProps = {
  onGetHistoryClick?: () => void;
  isLoading?: boolean;
  error?: RequestError | null;
  history?: THistoryItem[];
  noMargin?: boolean;
  pagination?: TablePaginationConfig | false;
  buttonAdditionalText?: string;
  isExtended?: boolean;
  withoutPerson?: boolean;
};

export const HistoryFooter: FC<THistoryFooterProps> = ({
  onGetHistoryClick,
  isLoading,
  error,
  history,
  noMargin,
  pagination,
  buttonAdditionalText = "историю",
  isExtended,
  withoutPerson
}) => {
  const [open, setOpen] = useState(!onGetHistoryClick);

  const onClickHandler = useCallback(() => {
    if (!open) {
      onGetHistoryClick?.();
    }
    setOpen((prev) => !prev);
  }, [onGetHistoryClick, open]);

  return (
    <Wrapper $noMargin={!!noMargin}>
      {!!onGetHistoryClick && (
        <Button
          size="small"
          onClick={onClickHandler}
          type="link"
          icon={open ? <MinusOutlined /> : <PlusOutlined />}
          loading={isLoading}
          style={{ alignSelf: "flex-start" }}
        >
          {open ? "Скрыть" : "Посмотреть"} {buttonAdditionalText}
        </Button>
      )}

      {open &&
        !isLoading &&
        (error ? (
          <ErrorAlert error={error} />
        ) : (
          history !== undefined && (
            <>
              <HistoryList
                history={history}
                isExtended={isExtended}
                withoutPerson={withoutPerson}
              />
              {pagination && (
                <Pagination {...pagination} align="end" size="small" />
              )}
            </>
          )
        ))}
    </Wrapper>
  );
};
