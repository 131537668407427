import { TAccount } from "./accountTypes";
import {
  TApplication,
  TApplicationApprovement,
  TApplicationForSeller,
  TApplicationPosition
} from "./applicationTypes";
import { ECabinetRole, TCabinet } from "./cabinetTypes";
import { TChat, TChatMessage } from "./chatTypes";
import { TEntity } from "./entityTypes";
import { TLicense, TLicenseType } from "./licenseTypes";
import { TLocation } from "./locationTypes";
import { TOfferForBuyer, TOfferForSeller, TOfferPosition } from "./offerTypes";
import { TPerson, TPersonEntity } from "./personTypes";
import { TProject } from "./projectTypes";

export type THistoryItem = {
  action: EHistoryItemAction;
  cabinetId?: number;
  entityData: unknown;
  // dtoData: unknown;
  entityId: number;
  // entityType: EHistoryItemEntityType;
  id: number;
  modifiedAt: number;
  modifiedBy: TPerson;
  status?: number;
} & THistoryItemDto;

export enum EHistoryItemAction {
  CREATE = "CREATE",
  UPDATE = "UPDATE",
  UPDATE_STATUS = "UPDATE_STATUS",
  DELETE = "DELETE",
  SOFT_DELETE = "SOFT_DELETE"
}

export enum EHistoryItemEntityType {
  ACCOUNT = "ACCOUNT",
  APPLICATION = "APPLICATION",
  APPLICATION_POSITION = "APPLICATION_POSITION",
  APPROVEMENT = "APPROVEMENT",
  CABINET = "CABINET",
  CABINET_ROLE = "CABINET_ROLE",
  CHAT = "CHAT",
  CHAT_MESSAGE = "CHAT_MESSAGE",
  DICTIONARY = "DICTIONARY",
  LEGAL_ENTITY = "LEGAL_ENTITY",
  LICENSE = "LICENSE",
  LICENSE_TYPE = "LICENSE_TYPE",
  LOCATION = "LOCATION",
  NOTIFICATION = "NOTIFICATION",
  OFFER = "OFFER",
  OFFER_POSITION = "OFFER_POSITION",
  PROJECT = "PROJECT",
  REGISTRATION = "REGISTRATION",
  RESOURCE_CODE_DICTIONARY = "RESOURCE_CODE_DICTIONARY",
  ROLE = "ROLE",
  USER = "USER",
  UZ = "UZ"
}

export type THistoryItemDto =
  | {
      entityType: EHistoryItemEntityType.ACCOUNT;
      dtoData: TAccount;
    }
  | {
      entityType: EHistoryItemEntityType.APPLICATION;
      dtoData: TApplication | TApplicationForSeller;
    }
  | {
      entityType: EHistoryItemEntityType.APPLICATION_POSITION;
      dtoData: TApplicationPosition;
    }
  | {
      entityType: EHistoryItemEntityType.APPROVEMENT;
      dtoData: TApplicationApprovement;
    }
  | {
      entityType: EHistoryItemEntityType.CABINET;
      dtoData: TCabinet;
    }
  | {
      entityType: EHistoryItemEntityType.CABINET_ROLE;
      dtoData: ECabinetRole;
    }
  | {
      entityType: EHistoryItemEntityType.CHAT;
      dtoData: TChat;
    }
  | {
      entityType: EHistoryItemEntityType.CHAT_MESSAGE;
      dtoData: TChatMessage;
    }
  | {
      entityType: EHistoryItemEntityType.DICTIONARY;
      dtoData: unknown;
    }
  | {
      entityType: EHistoryItemEntityType.LEGAL_ENTITY;
      dtoData: TEntity;
    }
  | {
      entityType: EHistoryItemEntityType.LICENSE;
      dtoData: TLicense;
    }
  | {
      entityType: EHistoryItemEntityType.LICENSE_TYPE;
      dtoData: TLicenseType;
    }
  | {
      entityType: EHistoryItemEntityType.LOCATION;
      dtoData: TLocation;
    }
  | {
      entityType: EHistoryItemEntityType.NOTIFICATION;
      dtoData: unknown;
    }
  | {
      entityType: EHistoryItemEntityType.OFFER;
      dtoData: TOfferForBuyer | TOfferForSeller;
    }
  | {
      entityType: EHistoryItemEntityType.OFFER_POSITION;
      dtoData: TOfferPosition;
    }
  | {
      entityType: EHistoryItemEntityType.PROJECT;
      dtoData: TProject;
    }
  | {
      entityType: EHistoryItemEntityType.REGISTRATION;
      dtoData: unknown;
    }
  | {
      entityType: EHistoryItemEntityType.RESOURCE_CODE_DICTIONARY;
      dtoData: unknown;
    }
  | {
      entityType: EHistoryItemEntityType.ROLE;
      dtoData: unknown;
    }
  | {
      entityType: EHistoryItemEntityType.USER;
      dtoData: TPerson;
    }
  | {
      entityType: EHistoryItemEntityType.UZ;
      dtoData: TPersonEntity;
    };
