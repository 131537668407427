import { createAsyncThunk } from "@reduxjs/toolkit";
import { historyByCabinetIdActions } from "./byCabinetId";
import { historyByTypeAndEntityIdActions } from "./byTypeAndEntityId";
import { historyStatusesByTypeAndEntityIdActions } from "./statusesByTypeAndEntityId";
import { historyByUserIdActions } from "./byUserId";

export const clearHistory = createAsyncThunk<void, void>(
  "history/clearHistory",
  (payload, { dispatch, rejectWithValue }) => {
    dispatch(historyByCabinetIdActions.clearState());
    dispatch(historyByUserIdActions.clearState());
    dispatch(historyByTypeAndEntityIdActions.clearState());
    dispatch(historyStatusesByTypeAndEntityIdActions.clearState());
  }
);
