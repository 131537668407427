import React, { FC, useCallback, useMemo } from "react";
import { Panel } from "..";
import { Button, Form, Input, Select } from "antd";
import { ECabinetRole, RequestError, TCabinet } from "types";
import { ErrorAlert } from "..";
import {
  cabinetRoleDefaultOptions,
  cabinetRoleOptions
} from "constants/options";

type TCabinetEditFormProps = {
  cabinet?: TCabinet;
  onSubmit: (values: TCabinet) => void;
  isLoading: boolean;
  error: RequestError | null;
};

export const CabinetEditForm: FC<TCabinetEditFormProps> = ({
  cabinet,
  onSubmit,
  isLoading,
  error
}) => {
  const [form] = Form.useForm<TCabinet>();

  const cabinetRole = Form.useWatch("cabinetRole", form);

  const isMultiple = useMemo(
    () => cabinetRole === ECabinetRole.MULTIPLE,
    [cabinetRole]
  );

  const onCabinetRoleChange = useCallback(
    (value: ECabinetRole) => {
      if (value === ECabinetRole.MULTIPLE) {
        form.setFieldValue("cabinetDefaultRole", ECabinetRole.BUYER);
      } else {
        form.setFieldValue("cabinetDefaultRole", value);
      }
    },
    [form]
  );

  return (
    <Panel
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <Form
        name="basic"
        form={form}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 28 }}
        // style={{ maxWidth: "600px" }}
        onFinish={onSubmit}
        // onFinishFailed={onFinishFailed}
        autoComplete="off"
        labelWrap
        colon={false}
        initialValues={cabinet}
      >
        <Form.Item<TCabinet>
          label="Имя"
          name="name"
          rules={[{ required: true, whitespace: true, message: "Введите имя" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item<TCabinet>
          label="Сторона"
          name="cabinetRole"
          rules={[
            {
              required: true,
              message: "Укажите сторону"
            }
          ]}
        >
          <Select options={cabinetRoleOptions} onChange={onCabinetRoleChange} />
        </Form.Item>
        <Form.Item<TCabinet>
          label="Приоритетная сторона"
          name="cabinetDefaultRole"
          rules={[
            {
              required: true,
              message: "Укажите приоритетную сторону"
            }
          ]}
        >
          <Select
            options={
              isMultiple ? cabinetRoleDefaultOptions : cabinetRoleOptions
            }
            disabled={cabinetRole !== ECabinetRole.MULTIPLE}
          />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            Сохранить
          </Button>
        </Form.Item>

        <ErrorAlert error={error} />
      </Form>
    </Panel>
  );
};
