import { THistoryItem } from "types";

export const historyTypeMapper: Record<THistoryItem["entityType"], string> = {
  ACCOUNT: "Счет",
  APPLICATION: "Заявка",
  APPLICATION_POSITION: "Позиция заявки",
  APPROVEMENT: "Согласование",
  CABINET: "Кабинет",
  CABINET_ROLE: "Роль в кабинете",
  CHAT: "Чат",
  CHAT_MESSAGE: "Сообщение",
  DICTIONARY: "Словарь",
  LEGAL_ENTITY: "Юр. лицо",
  LICENSE: "Лицензия",
  LICENSE_TYPE: "Тип лицензии",
  LOCATION: "Адрес",
  NOTIFICATION: "Уведомление",
  OFFER: "Предложение",
  OFFER_POSITION: "Позиция предложения",
  PROJECT: "Проект",
  REGISTRATION: "Регистрация",
  RESOURCE_CODE_DICTIONARY: "Словарь кода ресурса",
  ROLE: "Роль",
  USER: "Пользователь",
  UZ: "Учетная запись"
};
