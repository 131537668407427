import React, { FC, useCallback, useMemo, useState } from "react";
import { Button, Form, Input, Radio } from "antd";
import {
  ECabinetRole,
  RequestError,
  TEntity,
  TPerson,
  TPersonEntity
} from "types";
import { EntitiesSelector } from "./components";
import { ErrorAlert } from "../../old";
import { CTABlock } from "./UserEditForm.styles";
import { DeleteFilled, LeftOutlined } from "@ant-design/icons";
import { ConfirmDelete } from "../ConfirmDelete/ConfirmDelete";
import { BasicTitle } from "../BasicTitle/BasicTitle";
import { ProfileAvatar } from "../ProfileAvatar/ProfileAvatar";
import { TypographyTitleNoMargin } from "../TypographyTitleNoMargin/TypographyTitleNoMargin";
import { PhoneMaskedInput } from "../PhoneMaskedInput/PhoneMaskedInput";

type TUserEntitiyEditFormValues = Omit<TPersonEntity, "entity"> & {
  entity: number;
};
type TUserEditFormValues = Omit<TPerson, "entities"> & {
  entities?: TUserEntitiyEditFormValues[];
};

type TUserEditFormProps = {
  user?: TPerson;
  currentRole?: ECabinetRole;
  entities?: TPersonEntity[];
  onCancelEdit: () => void;
  onSubmit: (values: TPerson) => void;
  submitIsLoading: boolean;
  submitError: RequestError | null;
  onDelete?: () => Promise<void> | undefined;
  deleteIsLoading?: boolean;
  deleteError?: RequestError | null;
  isColleague?: boolean;
  isSeller: boolean;
};

export const UserEditForm: FC<TUserEditFormProps> = ({
  user,
  currentRole,
  entities,
  onCancelEdit,
  onSubmit,
  submitIsLoading,
  submitError,
  onDelete,
  deleteIsLoading,
  deleteError,
  isColleague,
  isSeller
}) => {
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const [form] = Form.useForm<TUserEditFormValues>();

  const lastName = Form.useWatch("last_name", form);
  const firstName = Form.useWatch("first_name", form);

  const initialValues: Partial<TUserEditFormValues> = useMemo(
    () =>
      user
        ? {
            ...user,
            entities: user?.entities?.map(({ entity, role_id }) => ({
              role_id,
              entity: entity?.entity_id
            })),
            ignore_notification: !user?.ignore_notification,
            ignore_email: !user?.ignore_email
          }
        : {
            ignore_notification: true,
            ignore_email: true
          },
    [user]
  );

  const onSubmitHandler = useCallback(
    (updUser: TUserEditFormValues) => {
      onSubmit({
        ...updUser,
        entities: updUser?.entities?.map(({ entity: entityId, role_id }) => ({
          role_id,
          entity: entities?.find(
            (entity) => entity?.entity?.entity_id === entityId
          )?.entity as TEntity
        })),
        ...(!user ? { login: updUser?.email } : {}),
        ignore_notification: !updUser?.ignore_notification,
        ignore_email: !updUser?.ignore_email
      });
    },
    [entities, onSubmit, user]
  );

  const onDeleteHandler = useCallback(() => {
    onDelete?.()?.then(() => {
      closeModal();
    });
  }, [onDelete]);

  const showModal = useCallback(() => {
    setOpenDeleteModal(true);
  }, []);

  const closeModal = () => {
    setOpenDeleteModal(false);
  };

  return (
    <>
      <BasicTitle>
        <Button icon={<LeftOutlined />} onClick={onCancelEdit} type="text" />
        Общая информация
      </BasicTitle>

      <Form
        name="user_edit"
        form={form}
        layout="vertical"
        requiredMark={false}
        style={{ maxWidth: "400px" }}
        onFinish={onSubmitHandler}
        autoComplete="off"
        initialValues={initialValues}
      >
        <Form.Item>
          <ProfileAvatar
            size={64}
            shape="square"
            isSeller={currentRole === ECabinetRole.SELLER}
          >
            {lastName?.[0]}
            {firstName?.[0]}
          </ProfileAvatar>
        </Form.Item>

        <Form.Item<TUserEditFormValues>
          label="Фамилия"
          name="last_name"
          rules={[
            {
              required: true,
              whitespace: true,
              message: "Введите фамилию"
            }
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item<TUserEditFormValues>
          label="Имя"
          name="first_name"
          rules={[
            {
              required: true,
              whitespace: true,
              message: "Введите имя"
            }
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item<TUserEditFormValues> label="Отчество" name="middle_name">
          <Input />
        </Form.Item>

        <Form.Item<TUserEditFormValues>
          label="Контантный телефон"
          name="phone"
          rules={[
            {
              required: true,
              pattern: new RegExp(
                /^((7|8|\+7)[- ]?)(\(?\d{3}\)?[- ]?)?[\d\- ]{7,10}$/
              ),
              message: "Введите корректный номер"
            }
          ]}
        >
          <PhoneMaskedInput />
        </Form.Item>

        {!user && (
          <Form.Item<TUserEditFormValues>
            label="Email"
            name="email"
            rules={[
              {
                required: true,
                message: "Введите корректный email",
                type: "email"
              }
            ]}
          >
            <Input />
          </Form.Item>
        )}

        <Form.Item<TUserEditFormValues>
          label="Должность"
          name="position"
          rules={[
            {
              whitespace: true,
              message: "Введите должность"
            }
          ]}
        >
          <Input />
        </Form.Item>

        {entities && (
          <EntitiesSelector entities={entities} isSeller={isSeller} />
        )}

        <Form.Item>
          <TypographyTitleNoMargin level={5}>Настройки</TypographyTitleNoMargin>
        </Form.Item>

        <Form.Item<TUserEditFormValues>
          label="Получать уведомления в приложении?"
          name="ignore_notification"
          rules={[
            {
              required: true,
              message: "Выберите одну из опций"
            }
          ]}
        >
          <Radio.Group>
            <Radio value={true}>Да</Radio>
            <Radio value={false}>Нет</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item<TUserEditFormValues>
          label="Получать уведомления на email?"
          name="ignore_email"
          rules={[
            {
              required: true,
              message: "Выберите одну из опций"
            }
          ]}
        >
          <Radio.Group>
            <Radio value={true}>Да</Radio>
            <Radio value={false}>Нет</Radio>
          </Radio.Group>
        </Form.Item>

        <ErrorAlert error={submitError} />
      </Form>
      <CTABlock>
        <Button
          type="primary"
          loading={submitIsLoading}
          form="user_edit"
          htmlType="submit"
        >
          Сохранить
        </Button>

        {user && (
          <Button
            icon={<DeleteFilled />}
            danger
            onClick={showModal}
            loading={deleteIsLoading}
          >
            Удалить профиль
          </Button>
        )}
      </CTABlock>
      <ConfirmDelete
        open={openDeleteModal}
        onClose={closeModal}
        onDelete={onDeleteHandler}
        isLoading={deleteIsLoading}
        error={deleteError}
        title={
          isColleague
            ? `Удалить коллегу ${user?.last_name} ${user?.first_name}?`
            : "Удалить профиль"
        }
        subtitle={
          isColleague
            ? "Вы уверены, что хотите удалить этого коллегу из вашего личного кабинета? У него больше не будет доступа к совместным данным и проектам. Подтвердить удаление?"
            : "Вы действительно хотите удалить свой профиль? Все данные и настройки будут удалены, и вы потеряете доступ к своему аккаунту. Подтвердить удаление?"
        }
      />
    </>
  );
};
