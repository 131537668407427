import { createAsyncThunk } from "@reduxjs/toolkit";
import { historyByUserIdActions, historyByUserIdSelectors } from ".";
import * as api from "api/history";
import { RequestError } from "types";
import { serializeError } from "serialize-error";
import { RootState } from "../../store";
import { userSelectors } from "store/user";

export const getHistoryByUserId = createAsyncThunk<
  api.TGetHistoryByUserResponse,
  Pick<api.TGetHistoryByUserRequest, "id">,
  { state: RootState }
>(
  "history/getHistoryByUserId",
  (payload, { dispatch, rejectWithValue, getState }) => {
    dispatch(historyByUserIdActions.setLoading(true));
    dispatch(historyByUserIdActions.setError(null));

    const { page, size } = historyByUserIdSelectors.getState(getState());
    const { user } = userSelectors.getState(getState());

    if (!user) return Promise.reject();

    return api
      .getHistoryByUser({
        id: payload?.id,
        page: page - 1,
        size
      })
      .then(({ data }) => {
        dispatch(historyByUserIdActions.setHistory(data?.content));
        dispatch(historyByUserIdActions.setTotalElements(data?.totalElements));
        return data;
      })
      .catch((err: RequestError) => {
        dispatch(historyByUserIdActions.setError(err));
        return rejectWithValue(serializeError(err));
      })
      .finally(() => {
        dispatch(historyByUserIdActions.setLoading(false));
      });
  }
);
