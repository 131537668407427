import { createAsyncThunk } from "@reduxjs/toolkit";
import { historyByTypeAndEntityIdActions } from ".";
import * as api from "api/history";
import { RequestError } from "types";
import { serializeError } from "serialize-error";
import { RootState } from "../../store";

export const getHistoryByTypeAndEntityId = createAsyncThunk<
  api.TGetHistoryByTypeAndEntityIdResponse,
  api.TGetHistoryByTypeAndEntityIdRequest & {
    byEntity?: boolean;
  },
  { state: RootState }
>(
  "history/getHistoryByTypeAndEntityId",
  (payload, { dispatch, rejectWithValue, getState }) => {
    const { byEntity, type, id } = payload || {
      byEntity: false,
      type: undefined,
      id: undefined
    };

    if (byEntity) {
      dispatch(
        historyByTypeAndEntityIdActions.prepareStoreByTypeId({ type, id })
      );
      dispatch(
        historyByTypeAndEntityIdActions.setLoadingByTypeId({
          type,
          id,
          isLoading: true
        })
      );
      dispatch(
        historyByTypeAndEntityIdActions.setErrorByTypeId({
          type,
          id,
          error: null
        })
      );
    } else {
      dispatch(historyByTypeAndEntityIdActions.setLoading(true));
      dispatch(historyByTypeAndEntityIdActions.setError(null));
    }

    return api
      .getHistoryByTypeAndEntityId(payload)
      .then(({ data }) => {
        if (byEntity) {
          dispatch(
            historyByTypeAndEntityIdActions.setHistoryByTypeId({
              type,
              id,
              history: data
            })
          );
        } else {
          dispatch(historyByTypeAndEntityIdActions.setHistory(data));
        }

        return data;
      })
      .catch((err: RequestError) => {
        if (byEntity) {
          dispatch(
            historyByTypeAndEntityIdActions.setErrorByTypeId({
              type,
              id,
              error: err
            })
          );
        } else {
          dispatch(historyByTypeAndEntityIdActions.setError(err));
        }
        return rejectWithValue(serializeError(err));
      })
      .finally(() => {
        if (byEntity) {
          dispatch(
            historyByTypeAndEntityIdActions.setLoadingByTypeId({
              type,
              id,
              isLoading: false
            })
          );
        } else {
          dispatch(historyByTypeAndEntityIdActions.setLoading(false));
        }
      });
  }
);
