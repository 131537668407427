import { PageableResponse, THistoryItem } from "types";
import { RequestPromise, apiRequest } from "utils/api";

export const getHistoryByUser = (
  data: TGetHistoryByUserRequest
): RequestPromise<TGetHistoryByUserResponse> => {
  const { id, ...params } = data;
  return apiRequest.get(`/history/user/${id}`, {
    params
  });
};

export type TGetHistoryByUserRequest = {
  id: number;
  size: number;
  page: number;
};

export type TGetHistoryByUserResponse = PageableResponse<THistoryItem>;
