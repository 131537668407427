import { EHistoryItemEntityType, THistoryItem } from "types";
import {
  applicationApprovementTypeIdMapper,
  cabinetRoleMapper,
  historyTypeMapper
} from "./mappers";

export const getEntityNameByHistoryItem = (
  item: THistoryItem
): string | undefined => {
  const entityType = item?.entityType;

  if (!entityType) {
    return;
  }

  const entityTypeName = historyTypeMapper[item?.entityType];

  const entityName =
    entityType === EHistoryItemEntityType.ACCOUNT
      ? `"${item?.dtoData?.name}"`
      : entityType === EHistoryItemEntityType.APPLICATION
        ? `"${item?.dtoData?.application_name}"`
        : entityType === EHistoryItemEntityType.APPLICATION_POSITION
          ? `"${item?.dtoData?.position_name}"`
          : entityType === EHistoryItemEntityType.APPROVEMENT
            ? `для ${applicationApprovementTypeIdMapper[item?.dtoData?.approvement_type_id]} "${item?.dtoData?.parent_name}"`
            : entityType === EHistoryItemEntityType.CABINET
              ? `"${item?.dtoData?.name}"`
              : entityType === EHistoryItemEntityType.CABINET_ROLE
                ? typeof item?.dtoData === "string"
                  ? `"${cabinetRoleMapper[item?.dtoData]}"`
                  : undefined
                : entityType === EHistoryItemEntityType.CHAT
                  ? `по "Заявке №${item?.dtoData?.applicationId}"`
                  : entityType === EHistoryItemEntityType.CHAT_MESSAGE
                    ? `"${item?.dtoData?.message}"`
                    : entityType === EHistoryItemEntityType.DICTIONARY
                      ? undefined
                      : entityType === EHistoryItemEntityType.LEGAL_ENTITY
                        ? `"${item?.dtoData?.full_name}"`
                        : entityType === EHistoryItemEntityType.LICENSE
                          ? `для "Кабинета №${item?.dtoData?.cabinet_id}"`
                          : entityType === EHistoryItemEntityType.LICENSE_TYPE
                            ? `"${item?.dtoData?.name}"`
                            : entityType === EHistoryItemEntityType.LOCATION
                              ? `"${item?.dtoData?.address}"`
                              : entityType ===
                                  EHistoryItemEntityType.NOTIFICATION
                                ? undefined
                                : entityType === EHistoryItemEntityType.OFFER
                                  ? `№${item?.dtoData?.offer_id} к Заявке "${item?.dtoData?.application_name}"`
                                  : entityType ===
                                      EHistoryItemEntityType.OFFER_POSITION
                                    ? `"${item?.dtoData?.position_name}"`
                                    : entityType ===
                                        EHistoryItemEntityType.PROJECT
                                      ? `"${item?.dtoData?.project_name}"`
                                      : entityType ===
                                          EHistoryItemEntityType.REGISTRATION
                                        ? undefined
                                        : entityType ===
                                            EHistoryItemEntityType.RESOURCE_CODE_DICTIONARY
                                          ? undefined
                                          : entityType ===
                                              EHistoryItemEntityType.ROLE
                                            ? undefined
                                            : entityType ===
                                                EHistoryItemEntityType.USER
                                              ? `"${item?.dtoData?.full_name}"`
                                              : entityType ===
                                                  EHistoryItemEntityType.UZ
                                                ? `для компании "${item?.dtoData?.entity?.full_name}"`
                                                : undefined;

  return `${entityTypeName} ${entityName || item?.entityId}`;
};
