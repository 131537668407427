import styled from "@emotion/styled";
import { DEVICE } from "constants/breakpoints";

export const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;

  @media ${DEVICE.LG} {
    gap: 16px;
  }
`;

export const Header = styled.div`
  display: flex;
  gap: 12px 24px;
  justify-content: space-between;
  flex-wrap: wrap;

  @media ${DEVICE.LG} {
    padding-bottom: 32px;
  }
  @media ${DEVICE.SM} {
    flex-direction: column;
    padding-bottom: 0;
  }
`;
