import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Header, Wrapper } from "../../Profile.styles";
import { useAppDispatch, useAppSelector } from "store/store";
import { userSelectors } from "store/user";
import { entityAddActions, entityAddSelectors } from "store/entities/add";
import {
  entityDeleteActions,
  entityDeleteSelectors
} from "store/entities/delete";
import {
  entityUpdateActions,
  entityUpdateSelectors
} from "store/entities/update";
import { EHistoryItemEntityType, TEntity, TPersonEntity } from "types";
import { addEntity } from "store/entities/add/thunk";
import { updateEntity } from "store/entities/update/thunk";
import { deleteEntity } from "store/entities/delete/thunk";
import {
  BasicTitle,
  CompanyData,
  CompanyEditForm,
  Divider,
  HistoryFooter
} from "components/common/redesign";
import { Button, Collapse } from "antd";
import { AccordionGroup } from "./CompaniesTab.styles";
import { EditOutlined } from "@ant-design/icons";
import { getUser } from "store/user/thunk";
import {
  historyByTypeAndEntityIdActions,
  historyByTypeAndEntityIdSelectors
} from "store/history/byTypeAndEntityId";
import { getHistoryByTypeAndEntityId } from "store/history/byTypeAndEntityId/thunk";

export const CompaniesTab = () => {
  const dispatch = useAppDispatch();

  const { user, isAdmin } = useAppSelector(userSelectors.getState);
  const { isLoading: addIsLoading, error: addError } = useAppSelector(
    entityAddSelectors.getState
  );
  const { isLoading: updateIsLoading, error: updateError } = useAppSelector(
    entityUpdateSelectors.getState
  );
  const { isLoading: deleteIsLoading, error: deleteError } = useAppSelector(
    entityDeleteSelectors.getState
  );
  const { historyByType } = useAppSelector(
    historyByTypeAndEntityIdSelectors.getState
  );

  const [isAdding, setIsAdding] = useState(false);
  const [updatingEntity, setUpdatingEntity] = useState<TEntity | undefined>(
    undefined
  );

  const canAddDelete = useMemo(
    () => user?.cabinet_role_id === 2 || isAdmin,
    [isAdmin, user?.cabinet_role_id]
  );
  const getCanUpdate = useCallback(
    (entity: TPersonEntity) =>
      user?.cabinet_role_id === 2 || entity?.role_id === 4 || isAdmin,
    [isAdmin, user?.cabinet_role_id]
  );

  const onAdd = useCallback(() => {
    setIsAdding(true);
  }, []);
  const onCancelAdd = useCallback(() => {
    setIsAdding(false);
  }, []);

  const onEdit = useCallback((entity: TEntity) => {
    setUpdatingEntity(entity);
  }, []);
  const onCancelEdit = useCallback(() => {
    setUpdatingEntity(undefined);
  }, []);

  const onAddEntity = useCallback(
    (values: TEntity) => {
      dispatch(addEntity({ ...values, cabinet_id: user!.cabinet?.cabinet_id }))
        .unwrap()
        .then(() => {
          onCancelAdd();
          dispatch(getUser());
        });
    },
    [dispatch, onCancelAdd, user]
  );

  const onUpdateEntity = useCallback(
    (values: TEntity) => {
      dispatch(updateEntity({ ...updatingEntity, ...values }))
        .unwrap()
        .then(() => {
          onCancelEdit();
          dispatch(getUser());
        });
    },
    [dispatch, onCancelEdit, updatingEntity]
  );

  const onDeleteEntity = useCallback(() => {
    return (
      updatingEntity &&
      dispatch(deleteEntity(updatingEntity?.entity_id))
        .unwrap()
        .then(() => {
          onCancelEdit();
          dispatch(getUser());
        })
    );
  }, [dispatch, onCancelEdit, updatingEntity]);

  const getHistoryById = useCallback(
    (id: number) => {
      dispatch(
        getHistoryByTypeAndEntityId({
          type: EHistoryItemEntityType.LEGAL_ENTITY,
          id,
          byEntity: true
        })
      );
    },
    [dispatch]
  );

  const getHistoryProps = useCallback(
    (id: number) => {
      return historyByType[EHistoryItemEntityType.LEGAL_ENTITY]?.[id] || {};
    },
    [historyByType]
  );

  useEffect(() => {
    return () => {
      dispatch(entityAddActions.clearState());
      dispatch(entityUpdateActions.clearState());
      dispatch(entityDeleteActions.clearState());
      dispatch(
        historyByTypeAndEntityIdActions.clearStateByType(
          EHistoryItemEntityType.LEGAL_ENTITY
        )
      );
    };
  }, [dispatch]);

  return (
    <Wrapper>
      {isAdding ? (
        <CompanyEditForm
          onCancelEdit={onCancelAdd}
          onSubmit={onAddEntity}
          submitIsLoading={addIsLoading}
          submitError={addError}
        />
      ) : !!updatingEntity ? (
        <CompanyEditForm
          entity={updatingEntity}
          onCancelEdit={onCancelEdit}
          onSubmit={onUpdateEntity}
          submitIsLoading={updateIsLoading}
          submitError={updateError}
          canDelete={canAddDelete}
          onDelete={onDeleteEntity}
          deleteIsLoading={deleteIsLoading}
          deleteError={deleteError}
        />
      ) : (
        <>
          <Header>
            <BasicTitle>{user?.cabinet?.name}</BasicTitle>

            {canAddDelete && <Button onClick={onAdd}>Добавить юр. лицо</Button>}
          </Header>

          <AccordionGroup>
            {(user?.entities || []).map((entity, index, self) => (
              <>
                <Collapse
                  ghost
                  items={[
                    {
                      key: entity?.entity?.entity_id,
                      label: entity?.entity?.full_name,
                      children: (
                        <>
                          <CompanyData entity={entity?.entity} />
                          <HistoryFooter
                            onGetHistoryClick={() =>
                              getHistoryById(entity?.entity?.entity_id)
                            }
                            {...getHistoryProps(entity?.entity?.entity_id)}
                          />
                        </>
                      ),
                      extra: getCanUpdate(entity) ? (
                        <Button
                          icon={<EditOutlined />}
                          type="text"
                          onClick={() => onEdit(entity?.entity)}
                          size="small"
                        />
                      ) : undefined
                    }
                  ]}
                  size="large"
                />
                {index < self?.length - 1 && <Divider />}
              </>
            ))}
          </AccordionGroup>
        </>
      )}
    </Wrapper>
  );
};
