import { DEVICE } from "constants/breakpoints";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useNavigate, useParams } from "react-router-dom";
import {
  colleagueDeleteActions,
  colleagueDeleteSelectors
} from "store/colleagues/delete";
import { deleteColleague } from "store/colleagues/delete/thunk";
import {
  colleagueUpdateActions,
  colleagueUpdateSelectors
} from "store/colleagues/update";
import { updateColleague } from "store/colleagues/update/thunk";
import { useAppDispatch, useAppSelector } from "store/store";
import { userSelectors } from "store/user";
import { ECabinetRole, EHistoryItemEntityType, TPerson } from "types";
import { ProfileBlock } from "./Colleague.styles";
import {
  BasicTitle,
  ProfileAvatar,
  UserData,
  UserEditForm
} from "components/common/redesign";
import { Alert, Button } from "antd";
import { colleaguesByCabinetSelectors } from "store/colleagues/byCabinet";
import { getColleaguesByCabinet } from "store/colleagues/byCabinet/thunk";
import { LeftOutlined } from "@ant-design/icons";
import { Header, Wrapper } from "../../../../Profile.styles";
import {
  historyByTypeAndEntityIdActions,
  historyByTypeAndEntityIdSelectors
} from "store/history/byTypeAndEntityId";
import { getHistoryByTypeAndEntityId } from "store/history/byTypeAndEntityId/thunk";
import { getHistoryByUserId } from "store/history/byUserId/thunk";
import {
  historyByUserIdActions,
  historyByUserIdSelectors
} from "store/history/byUserId";
import { PAGE_SIZES } from "constants/pagination";

export const Colleague = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const isSM = useMediaQuery({ query: DEVICE.SM });

  const [isEditing, setIsEditing] = useState(false);

  const { user, currentRole, isAdmin } = useAppSelector(userSelectors.getState);
  const { users } = useAppSelector(colleaguesByCabinetSelectors.getState);
  const { isLoading: deleteIsLoading, error: deleteError } = useAppSelector(
    colleagueDeleteSelectors.getState
  );
  const { isLoading: updateIsLoading, error: updateError } = useAppSelector(
    colleagueUpdateSelectors.getState
  );
  const { historyByType } = useAppSelector(
    historyByTypeAndEntityIdSelectors.getState
  );
  const {
    history: activityHistory,
    isLoading: activityHistoryIsLoading,
    error: activityHistoryError,
    totalElements: activityHistoryTotalElements,
    page: activityHistoryPage,
    size: activityHistorySize
  } = useAppSelector(historyByUserIdSelectors.getState);

  const { user_id } = useParams<{ user_id: string }>();

  const colleague = useMemo(
    () => users?.find((colleague) => colleague?.user_id === Number(user_id)),
    [user_id, users]
  );

  const canUpdateDelete = useMemo(
    () => user?.cabinet_role_id === 2 || isAdmin,
    [isAdmin, user?.cabinet_role_id]
  );

  const historyProps = useMemo(() => {
    return colleague
      ? historyByType[EHistoryItemEntityType.USER]?.[colleague?.user_id] || {}
      : {};
  }, [colleague, historyByType]);

  const activityAvailable = useMemo(
    () => user?.cabinet_role_id === 2 || isAdmin,
    [isAdmin, user?.cabinet_role_id]
  );

  const getColleagues = useCallback(
    (cabinet_id: number) => {
      dispatch(getColleaguesByCabinet(cabinet_id));
    },
    [dispatch]
  );

  const onEdit = useCallback(() => {
    setIsEditing(true);
  }, []);
  const onCancelEdit = useCallback(() => {
    setIsEditing(false);
  }, []);

  const onGoBack = useCallback(() => {
    navigate("../");
  }, [navigate]);

  const onUpdate = useCallback(
    (values: TPerson) => {
      dispatch(updateColleague({ ...colleague, ...values }))
        .unwrap()
        .then(() => {
          onCancelEdit();
          user && getColleagues(user?.cabinet?.cabinet_id);
        });
    },
    [colleague, dispatch, getColleagues, onCancelEdit, user]
  );

  const onDelete = useCallback(() => {
    return (
      colleague &&
      dispatch(deleteColleague(colleague?.user_id))
        .unwrap()
        .then(() => {
          onGoBack();
        })
    );
  }, [colleague, dispatch, onGoBack]);

  const getHistoryById = useCallback(() => {
    colleague &&
      dispatch(
        getHistoryByTypeAndEntityId({
          type: EHistoryItemEntityType.USER,
          id: colleague?.user_id,
          byEntity: true
        })
      );
  }, [colleague, dispatch]);

  const getActivityHistoryById = useCallback(() => {
    colleague &&
      dispatch(
        getHistoryByUserId({
          id: colleague?.user_id
        })
      );
  }, [colleague, dispatch]);

  const onActivityHistoryPaginationChange = useCallback(
    (page: number, pageSize: number) => {
      if (pageSize === activityHistorySize) {
        dispatch(historyByUserIdActions.setPage(page));
      } else {
        dispatch(historyByUserIdActions.setPage(1));
        dispatch(historyByUserIdActions.setSize(pageSize));
      }
      getActivityHistoryById();
    },
    [activityHistorySize, dispatch, getActivityHistoryById]
  );

  useEffect(() => {
    if (Number(user_id) === user?.user_id) {
      navigate("/profile", { replace: true });
    }
  }, [user_id, navigate, user?.user_id]);

  useEffect(() => {
    !users && user && getColleagues(user?.cabinet?.cabinet_id);
  }, [getColleagues, user, users]);

  useEffect(() => {
    return () => {
      dispatch(colleagueDeleteActions.clearState());
      dispatch(colleagueUpdateActions.clearState());
      dispatch(
        historyByTypeAndEntityIdActions.clearStateByType(
          EHistoryItemEntityType.USER
        )
      );
      dispatch(historyByUserIdActions.clearState());
    };
  }, [dispatch]);

  return (
    <Wrapper>
      {!isEditing && (
        <BasicTitle>
          <Button icon={<LeftOutlined />} onClick={onGoBack} type="text" />
          Профиль коллеги
        </BasicTitle>
      )}
      {colleague ? (
        isEditing ? (
          <UserEditForm
            user={colleague}
            currentRole={currentRole}
            entities={user?.entities}
            onCancelEdit={onCancelEdit}
            onSubmit={onUpdate}
            submitIsLoading={updateIsLoading}
            submitError={updateError}
            onDelete={onDelete}
            deleteIsLoading={deleteIsLoading}
            deleteError={deleteError}
            isSeller={currentRole === ECabinetRole.SELLER}
          />
        ) : (
          <>
            <Header>
              <ProfileBlock>
                <ProfileAvatar
                  size={isSM ? 40 : 64}
                  shape="square"
                  isSeller={currentRole === ECabinetRole.SELLER}
                >
                  {colleague?.last_name?.[0]}
                  {colleague?.first_name?.[0]}
                </ProfileAvatar>
                <BasicTitle>{colleague?.full_name}</BasicTitle>
              </ProfileBlock>
            </Header>

            <UserData
              user={colleague}
              onEdit={onEdit}
              withEdit={!!canUpdateDelete}
              isSeller={currentRole === ECabinetRole.SELLER}
              onGetHistoryClick={getHistoryById}
              historyProps={historyProps}
              onGetActivityHistoryClick={
                activityAvailable ? getActivityHistoryById : undefined
              }
              activityHistoryProps={{
                history: activityHistory,
                isLoading: activityHistoryIsLoading,
                error: activityHistoryError,
                pagination: {
                  current: activityHistoryPage,
                  onChange: onActivityHistoryPaginationChange,
                  pageSize: activityHistorySize,
                  total: activityHistoryTotalElements,
                  showSizeChanger: true,
                  pageSizeOptions: PAGE_SIZES
                }
              }}
            />
          </>
        )
      ) : (
        <Alert message="Данные пользователя отсутствуют" showIcon />
      )}
    </Wrapper>
  );
};
