import React, { useCallback, useEffect, useMemo, useState } from "react";
import { cabinetsGetSelectors } from "store/cabinets/get";
import { getCabinets } from "store/cabinets/get/thunk";
import { useAppDispatch, useAppSelector } from "store/store";
import { TLocation } from "types";
import { Wrapper } from "./LocationsTab.styles";
import { Button, Select } from "antd";
import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import { LocationsTable } from "components/common/old";
import { getEntitiesByCabinetId } from "store/entities/byCabinetId/thunk";
import {
  entitiesByCabinetIdActions,
  entitiesByCabinetIdSelectors
} from "store/entities/byCabinetId";
import { adminActions, adminSelectors } from "store/admin";
import {
  locationsByCabinetIdActions,
  locationsByCabinetIdSelectors
} from "store/locations/byCabinet";
import { locationAddActions, locationAddSelectors } from "store/locations/add";
import {
  locationUpdateActions,
  locationUpdateSelectors
} from "store/locations/update";
import {
  locationDeleteActions,
  locationDeleteSelectors
} from "store/locations/delete";
import { getLocationsByCabinetId } from "store/locations/byCabinet/thunk";
import { addLocation } from "store/locations/add/thunk";
import { updateLocation } from "store/locations/update/thunk";
import { deleteLocation } from "store/locations/delete/thunk";
import { LocationEditForm } from "components/common/redesign";

export const LocationsTab = () => {
  const dispatch = useAppDispatch();

  const { cabinets } = useAppSelector(cabinetsGetSelectors.getState);
  const { locations } = useAppSelector(locationsByCabinetIdSelectors.getState);
  const { entities } = useAppSelector(entitiesByCabinetIdSelectors.getState);
  const { isLoading: addIsLoading, error: addError } = useAppSelector(
    locationAddSelectors.getState
  );
  const { isLoading: updateIsLoading, error: updateError } = useAppSelector(
    locationUpdateSelectors.getState
  );
  const { isLoading: deleteIsLoading } = useAppSelector(
    locationDeleteSelectors.getState
  );
  const { selectedCabinet } = useAppSelector(adminSelectors.getState);

  const [isAdding, setIsAdding] = useState(false);
  const [locationInEdit, setLocationInEdit] = useState<TLocation | undefined>(
    undefined
  );

  const cabinetsOptions = useMemo(
    () =>
      cabinets?.map((cabinet) => ({
        label: cabinet?.name,
        value: cabinet?.cabinet_id
      })),
    [cabinets]
  );

  const getCabinetsArr = useCallback(() => {
    dispatch(getCabinets());
  }, [dispatch]);

  const getLocations = useCallback(
    (cabinetId: number) => {
      dispatch(getLocationsByCabinetId(cabinetId));
    },
    [dispatch]
  );

  const getEntities = useCallback(
    (cabinetId: number) => {
      dispatch(getEntitiesByCabinetId(cabinetId));
    },
    [dispatch]
  );

  const onAdd = useCallback(() => {
    setIsAdding(true);
  }, []);
  const onCancelAdd = useCallback(() => {
    setIsAdding(false);
  }, []);

  const onEdit = useCallback(
    (id: number) => {
      const location = locations?.find(
        (location) => location?.location_id === id
      );
      setLocationInEdit(location);
    },
    [locations]
  );
  const onCancelEdit = useCallback(() => {
    setLocationInEdit(undefined);
  }, []);

  const onAddLocation = useCallback(
    (values: TLocation) => {
      dispatch(addLocation(values))
        .unwrap()
        .then(() => {
          onCancelAdd();
          selectedCabinet && getLocations(selectedCabinet);
        });
    },
    [dispatch, getLocations, onCancelAdd, selectedCabinet]
  );
  const onEditLocation = useCallback(
    (values: TLocation) => {
      dispatch(
        updateLocation({
          ...locationInEdit,
          ...values
        })
      )
        .unwrap()
        .then(() => {
          onCancelEdit();
          selectedCabinet && getLocations(selectedCabinet);
        });
    },
    [dispatch, getLocations, locationInEdit, onCancelEdit, selectedCabinet]
  );
  const onDeleteLocation = useCallback(
    (id: number) => {
      dispatch(deleteLocation(id))
        .unwrap()
        .then(() => {
          selectedCabinet && getLocations(selectedCabinet);
        });
    },
    [dispatch, getLocations, selectedCabinet]
  );
  const onSelectCabinet = useCallback(
    (value: number | undefined) => {
      dispatch(adminActions.setSelectedCabinet(value));
    },
    [dispatch]
  );

  useEffect(() => {
    !cabinets && getCabinetsArr();
  }, [cabinets, getCabinetsArr]);

  useEffect(() => {
    selectedCabinet && getLocations(selectedCabinet);
  }, [dispatch, getLocations, selectedCabinet]);

  useEffect(() => {
    selectedCabinet && getEntities(selectedCabinet);
  }, [dispatch, getEntities, selectedCabinet]);

  useEffect(() => {
    return () => {
      dispatch(locationAddActions.clearState());
      dispatch(locationUpdateActions.clearState());
      dispatch(locationDeleteActions.clearState());
      dispatch(locationsByCabinetIdActions.clearState());
      dispatch(entitiesByCabinetIdActions.clearState());
    };
  }, [dispatch]);

  return (
    <Wrapper>
      <Select
        placeholder="Выберите кабинет"
        options={cabinetsOptions}
        onChange={onSelectCabinet}
        value={selectedCabinet}
        style={{ maxWidth: 300 }}
      />
      {selectedCabinet && (
        <>
          {!locationInEdit && (
            <Button
              icon={isAdding ? <CloseOutlined /> : <PlusOutlined />}
              onClick={isAdding ? onCancelAdd : onAdd}
            >
              {isAdding ? "Отмена" : "Добавить"}
            </Button>
          )}

          {locationInEdit ? (
            <>
              <Button icon={<CloseOutlined />} onClick={onCancelEdit}>
                Отмена
              </Button>
              <LocationEditForm
                location={locationInEdit}
                entities={entities}
                onSubmit={onEditLocation}
                submitIsLoading={updateIsLoading}
                submitError={updateError}
              />
            </>
          ) : isAdding ? (
            <LocationEditForm
              entities={entities}
              onSubmit={onAddLocation}
              submitIsLoading={addIsLoading}
              submitError={addError}
            />
          ) : (
            <LocationsTable
              locations={locations}
              onEdit={onEdit}
              onDelete={onDeleteLocation}
              isDeleting={deleteIsLoading}
            />
          )}
        </>
      )}
    </Wrapper>
  );
};
