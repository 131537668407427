import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const Wrapper = styled.div<{ $noMargin: boolean }>`
  margin-top: 10px;
  display: flex;
  flex-direction: column;

  ${({ $noMargin }) =>
    $noMargin &&
    css`
      margin: 0;
    `}
`;

// export const TableWrapper = styled.div`
//   flex: 1;

//   display: flex;
//   flex-direction: column;
//   justify-content: space-between;
//   gap: 24px;

//   @media ${DEVICE.LG} {
//     gap: 16px;
//   }
// `;
