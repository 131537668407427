import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "store/store";
import { THistoryItem } from "types";
import { TimeStyled, Wrapper } from "./HistoryTab.styles";
import { ErrorAlert } from "components/common/old";
import {
  Button,
  Col,
  Form,
  Input,
  List,
  Modal,
  Row,
  Select,
  Tabs,
  Typography
} from "antd";
import { TGetHistoryByTypeAndEntityIdRequest } from "api/history";
import { getHistoryByTypeAndEntityId } from "store/history/byTypeAndEntityId/thunk";
import {
  historyByTypeAndEntityIdActions,
  historyByTypeAndEntityIdSelectors
} from "store/history/byTypeAndEntityId";
import { historyTypeOptions } from "constants/options/historyTypeMapper";
import { DEVICE } from "constants/breakpoints";
import { useMediaQuery } from "react-responsive";
import { historyActionMapper, historyTypeMapper } from "utils/mappers";
import { toDisplayDateTime } from "utils/dates";
import ReactDiffViewer from "react-diff-viewer";
import { Empty } from "components/common/redesign";

export const HistoryTab = () => {
  const dispatch = useAppDispatch();

  const isSM = useMediaQuery({ query: DEVICE.SM });

  const [compareItem, setCompareItem] = useState<THistoryItem | undefined>(
    undefined
  );

  const { history, isLoading, error } = useAppSelector(
    historyByTypeAndEntityIdSelectors.getState
  );

  const historyData = useMemo(
    () =>
      history?.map((item) => ({
        title: `${item?.modifiedBy?.full_name} ${historyActionMapper[item?.action]?.verb} "${historyTypeMapper[item?.entityType]} №${item?.entityId}"`,
        time: toDisplayDateTime(item?.modifiedAt),
        id: item?.id
      })),
    [history]
  );

  const { prevEntity, curEntity, prevDTO, curDTO } = useMemo(() => {
    if (compareItem === undefined) {
      return { prevEntity: undefined, curEntity: undefined };
    }

    const curIndex = history?.findIndex((item) => item?.id === compareItem?.id);
    if (curIndex === undefined) {
      return { prevEntity: undefined, curEntity: undefined };
    }

    return {
      prevEntity: JSON.stringify(
        history?.[curIndex + 1]?.entityData || {},
        undefined,
        2
      ),
      curEntity: JSON.stringify(
        history?.[curIndex]?.entityData || {},
        undefined,
        2
      ),
      prevDTO: JSON.stringify(
        history?.[curIndex + 1]?.dtoData || {},
        undefined,
        2
      ),
      curDTO: JSON.stringify(history?.[curIndex]?.dtoData || {}, undefined, 2)
    };
  }, [compareItem, history]);

  const onSearch = useCallback(
    (values: TGetHistoryByTypeAndEntityIdRequest) => {
      if (values?.id && values?.type) {
        dispatch(getHistoryByTypeAndEntityId(values));
      }
    },
    [dispatch]
  );

  const showCompareModal = useCallback(
    (id: number) => {
      setCompareItem(history?.find((item) => item?.id === id));
    },
    [history]
  );

  const closeCompareModal = useCallback(() => {
    setCompareItem(undefined);
  }, []);

  useEffect(() => {
    return () => {
      dispatch(historyByTypeAndEntityIdActions.clearState());
    };
  }, [dispatch]);

  return (
    <>
      <Wrapper>
        <Form<TGetHistoryByTypeAndEntityIdRequest>
          name="admin_history"
          layout="vertical"
          requiredMark={false}
          onFinish={onSearch}
          className="withRows"
        >
          <Row gutter={isSM ? 12 : 24} justify="space-between" align="bottom">
            <Col span={isSM ? 24 : 8}>
              <Form.Item<TGetHistoryByTypeAndEntityIdRequest>
                label="Сущность"
                name="type"
              >
                <Select options={historyTypeOptions} />
              </Form.Item>
            </Col>

            <Col span={isSM ? 24 : 8}>
              <Form.Item<TGetHistoryByTypeAndEntityIdRequest>
                label="ID"
                name="id"
              >
                <Input />
              </Form.Item>
            </Col>

            <Col span={isSM ? 24 : 8}>
              <Form.Item>
                <Button
                  htmlType="submit"
                  style={{ width: "100%" }}
                  loading={isLoading}
                  form="admin_history"
                  type="primary"
                >
                  Найти
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>

        {error ? (
          <ErrorAlert error={error} />
        ) : historyData?.length ? (
          <List
            size="small"
            dataSource={historyData}
            renderItem={(item) => (
              <List.Item
                actions={[
                  <Button
                    type="link"
                    onClick={() => showCompareModal(item?.id)}
                  >
                    Сравнить
                  </Button>
                ]}
              >
                <TimeStyled type="secondary">{item?.time}</TimeStyled>
                <List.Item.Meta
                  description={
                    <Typography.Text strong>{item?.title}</Typography.Text>
                  }
                />
              </List.Item>
            )}
          />
        ) : (
          <Empty />
        )}
      </Wrapper>

      <Modal
        open={!!compareItem}
        title={
          compareItem
            ? `${historyActionMapper[compareItem?.action]?.noun} "${historyTypeMapper[compareItem?.entityType]} №${compareItem?.entityId}" пользователем ${compareItem?.modifiedBy?.full_name} от ${toDisplayDateTime(compareItem?.modifiedAt)}`
            : ""
        }
        onCancel={closeCompareModal}
        footer={false}
        destroyOnClose
        width="fit-content"
      >
        <Tabs
          destroyInactiveTabPane
          items={[
            {
              key: "entityData",
              label: "Объект БД",
              children: (
                <ReactDiffViewer
                  oldValue={prevEntity}
                  newValue={curEntity}
                  leftTitle="Предыдущая версия"
                  rightTitle="Текущая версия"
                  splitView={!isSM}
                />
              )
            },
            {
              key: "dtoData",
              label: "DTO",
              children: (
                <ReactDiffViewer
                  oldValue={prevDTO}
                  newValue={curDTO}
                  leftTitle="Предыдущая версия"
                  rightTitle="Текущая версия"
                  splitView={!isSM}
                />
              )
            }
          ]}
        />
      </Modal>
    </>
  );
};
