import { TLocation } from "types";
import { RequestPromise, apiRequest } from "utils/api";

export const deleteLocation = (
  id: TDeleteLocationRequest
): RequestPromise<TDeleteLocationResponse> => {
  return apiRequest.post(`/location/delete/${id}`, undefined);
};

export type TDeleteLocationRequest = number;

export type TDeleteLocationResponse = TLocation;
