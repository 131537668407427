import { THistoryItem, EHistoryItemEntityType } from "types";
import { RequestPromise, apiRequest } from "utils/api";

export * from "./statuses";

export const getHistoryByTypeAndEntityId = (
  data: TGetHistoryByTypeAndEntityIdRequest
): RequestPromise<TGetHistoryByTypeAndEntityIdResponse> => {
  const { id, type } = data;
  return apiRequest.get(`/history/${id}/type/${type}`);
};

export type TGetHistoryByTypeAndEntityIdRequest = {
  type: EHistoryItemEntityType;
  id: number;
};

export type TGetHistoryByTypeAndEntityIdResponse = THistoryItem[];
