import React, { FC, useMemo } from "react";
import { List, Typography } from "antd";
import { EHistoryItemEntityType, THistoryItem } from "types";
import { toDisplayDateTime } from "utils/dates";
import { historyActionMapper } from "utils/mappers";
import { TimeStyled } from "./HistoryList.styles";
import { Empty } from "../Empty/Empty";
import { Link } from "react-router-dom";
import { getEntityNameByHistoryItem } from "utils/getEntityNameByHistoryItem";

type THistoryListProps = {
  history?: THistoryItem[];
  isExtended?: boolean;
  withoutPerson?: boolean;
};

export const HistoryList: FC<THistoryListProps> = ({
  history,
  isExtended,
  withoutPerson
}) => {
  const historyData = useMemo(
    () =>
      history?.map((item) => ({
        action: historyActionMapper[item?.action]?.noun,
        user: item?.modifiedBy,
        time: toDisplayDateTime(item?.modifiedAt),
        entityName: getEntityNameByHistoryItem(item),
        link:
          item?.entityType === EHistoryItemEntityType.APPLICATION
            ? `/applications/${item?.entityId}`
            : item?.entityType === EHistoryItemEntityType.OFFER
              ? `/offers/${item?.entityId}`
              : undefined
      })),
    [history]
  );
  return historyData?.length ? (
    <List
      size="small"
      dataSource={historyData}
      renderItem={(item) => (
        <List.Item>
          <TimeStyled type="secondary">{item?.time}</TimeStyled>
          <List.Item.Meta
            description={
              <Typography.Text>
                <Typography.Text strong>{item?.action}</Typography.Text>
                {isExtended && (
                  <>
                    {" "}
                    {item?.link ? (
                      <Typography.Text strong>
                        <Link to={item?.link}>{item?.entityName}</Link>
                      </Typography.Text>
                    ) : (
                      <Typography.Text strong>
                        {item?.entityName}
                      </Typography.Text>
                    )}
                  </>
                )}
                {!withoutPerson && (
                  <>
                    {" "}
                    (
                    <Link to={`/profile/colleagues/${item?.user?.user_id}`}>
                      {item?.user?.full_name}
                    </Link>
                    )
                  </>
                )}
              </Typography.Text>
            }
          />
        </List.Item>
      )}
    />
  ) : (
    <Empty />
  );
};
