import { createAsyncThunk } from "@reduxjs/toolkit";
import { locationDeleteActions } from ".";
import * as api from "api/locations";
import { RequestError } from "types";
import { serializeError } from "serialize-error";

export const deleteLocation = createAsyncThunk<
  api.TDeleteLocationResponse,
  api.TDeleteLocationRequest
>("location/deleteLocation", (payload, { dispatch, rejectWithValue }) => {
  dispatch(locationDeleteActions.setLoading(true));
  dispatch(locationDeleteActions.setError(null));
  return api
    .deleteLocation(payload)
    .then(({ data }) => {
      dispatch(locationDeleteActions.setLocation(data));
      return data;
    })
    .catch((err: RequestError) => {
      dispatch(locationDeleteActions.setError(err));
      return rejectWithValue(serializeError(err));
    })
    .finally(() => {
      dispatch(locationDeleteActions.setLoading(false));
    });
});
