import { THistoryItem, EHistoryItemEntityType } from "types";
import { RequestPromise, apiRequest } from "utils/api";

export const getHistoryStatusesByTypeAndEntityId = (
  data: TGetHistoryStatusesByTypeAndEntityIdRequest
): RequestPromise<TGetHistoryStatusesByTypeAndEntityIdResponse> => {
  const { id, type } = data;
  return apiRequest.get(`/history/${id}/type/${type}/statuses`);
};

export type TGetHistoryStatusesByTypeAndEntityIdRequest = {
  type: EHistoryItemEntityType;
  id: number;
};

export type TGetHistoryStatusesByTypeAndEntityIdResponse = THistoryItem[];
