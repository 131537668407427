import React, { FC, useCallback, useMemo, useState } from "react";
import { Button, Form, Input, Radio, Select } from "antd";
import { RequestError, TEntity, TLocation, TProject } from "types";
import { ErrorAlert } from "../../old";
import { CTABlock } from "./ProjectEditForm.styles";
import { DeleteFilled, LeftOutlined } from "@ant-design/icons";
import { ConfirmDelete } from "../ConfirmDelete/ConfirmDelete";
import { BasicTitle } from "../BasicTitle/BasicTitle";
import { projectRegionIdOptions } from "constants/options";

type TProjectEditFormProps = {
  project?: TProject;
  entities?: TEntity[];
  locations?: TLocation[];
  onCancelEdit: () => void;
  onSubmit: (values: TProject) => void;
  submitIsLoading: boolean;
  submitError: RequestError | null;
  onDelete?: () => Promise<void> | undefined;
  deleteIsLoading?: boolean;
  deleteError?: RequestError | null;
};

export const ProjectEditForm: FC<TProjectEditFormProps> = ({
  project,
  entities,
  locations,
  onCancelEdit,
  onSubmit,
  submitIsLoading,
  submitError,
  onDelete,
  deleteIsLoading,
  deleteError
}) => {
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const [form] = Form.useForm<TProject>();

  const projectName = Form.useWatch("project_name", form);
  const entity_id = Form.useWatch("entity_id", form);

  const entitiesOptions = useMemo(
    () =>
      (entities || []).map((entity) => ({
        label: entity?.full_name,
        value: entity?.entity_id
      })),
    [entities]
  );
  const locationsOptions = useMemo(
    () =>
      (locations || [])
        .filter((location) => location?.entity_id === entity_id)
        .map((location) => ({
          label: location?.address,
          value: location?.location_id
        })),
    [entity_id, locations]
  );

  const onDeleteHandler = useCallback(() => {
    onDelete?.()?.then(() => {
      closeModal();
    });
  }, [onDelete]);

  const showModal = useCallback(() => {
    setOpenDeleteModal(true);
  }, []);

  const closeModal = () => {
    setOpenDeleteModal(false);
  };

  const onEntityChange = useCallback(() => {
    form.setFieldValue("location_id", undefined);
  }, [form]);

  return (
    <>
      <BasicTitle>
        <Button icon={<LeftOutlined />} onClick={onCancelEdit} type="text" />
        {project ? project?.project_name : projectName}
      </BasicTitle>
      <Form
        name="project_edit"
        form={form}
        layout="vertical"
        requiredMark={false}
        style={{ maxWidth: "400px" }}
        onFinish={onSubmit}
        autoComplete="off"
        initialValues={project}
      >
        <Form.Item<TProject>
          label="Название"
          name="project_name"
          rules={[
            { required: true, whitespace: true, message: "Введите название" }
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item<TProject> label="Описание" name="project_description">
          <Input.TextArea showCount maxLength={100} autoSize />
        </Form.Item>

        <Form.Item<TProject>
          label="Юр. лицо"
          name="entity_id"
          rules={[
            {
              required: true,
              message: "Введите юр. лицо"
            }
          ]}
        >
          <Select options={entitiesOptions} onChange={onEntityChange} />
        </Form.Item>

        <Form.Item<TProject> label="Адрес доставки" name="location_id">
          <Select
            options={locationsOptions}
            allowClear
            disabled={!locationsOptions?.length}
          />
        </Form.Item>

        <Form.Item<TProject>
          label="Комментарий для доставки"
          name="delivery_comment"
        >
          <Input.TextArea showCount maxLength={100} autoSize />
        </Form.Item>

        <Form.Item<TProject>
          label="Район"
          name="region_id"
          rules={[
            {
              required: true,
              message: "Введите район"
            }
          ]}
        >
          <Select options={projectRegionIdOptions} />
        </Form.Item>

        <Form.Item<TProject>
          label="Активен"
          name="is_active"
          rules={[
            {
              required: true,
              message: "Выберите одну из опций"
            }
          ]}
        >
          <Radio.Group>
            <Radio value={true}>Да</Radio>
            <Radio value={false}>Нет</Radio>
          </Radio.Group>
        </Form.Item>

        <ErrorAlert error={submitError} />
      </Form>
      <CTABlock>
        <Button
          type="primary"
          loading={submitIsLoading}
          form="project_edit"
          htmlType="submit"
        >
          Сохранить
        </Button>

        {project && (
          <Button
            icon={<DeleteFilled />}
            danger
            onClick={showModal}
            loading={deleteIsLoading}
          >
            Удалить проект
          </Button>
        )}
      </CTABlock>
      <ConfirmDelete
        open={openDeleteModal}
        onClose={closeModal}
        onDelete={onDeleteHandler}
        isLoading={deleteIsLoading}
        error={deleteError}
        title={`Удалить проект ${project?.project_name}?`}
        subtitle={
          "Вы уверены, что хотите удалить этот проект из списка юридического лица? Данные проекта будут безвозвратно удалены, и доступ к ним будет потерян. Подтвердить удаление?"
        }
      />
    </>
  );
};
